<template>
  <div id="app">
    <div v-if="isOnboarding||isGatewayLoading||isStoreLoading" class="d-flex justify-content-center" id="plexiglass">
      <div class="spinner-border" role="status" id="spinner">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="$route.meta.renderType === 'migration'">
      <router-view></router-view>
    </div>
    <div v-else :class="isOnboarding?'wrap d-none':'wrap'" > <!-- :style="isOnboarding?'':'background-color:rgb(240, 240, 240) !important;'" -->
      <SbsNavbar />
      <!-- <div :id="(!authenticated)?'login-wrapper':''" class="container"> -->
      <div :id="(!isSideBarVisiable)?'login-wrapper':''" :style="(!isSideBarVisiable) ? 'display:flex;justify-content:center;' : 'width:94%;display:block;margin-left:6%;'"> <!-- background-color:rgb(240, 240, 240); -->
        <div class="row" :style="(isSideBarVisiable) ? '' : 'width:1170px;justify-content: center;'">
          <div :class="(!isSideBarVisiable) ? '' : 'col-xs-12 col-sm-12'">
            <div v-if="errors" class="container error-summary" v-html="errors">
              <!-- <div class="row" v-for="error in errors" v-html="error" :key="error"></div> -->
            </div>
            <!-- <div class="panel panel-default shadow-none"> -->
            <div class="shadow-none">
              <div v-if="!authenticated" class="panel-heading" style="background-color:#eee;border-bottom:1px solid #ddd;">
                <h3 class="panel-title">{{ $t('gateway/view:login:header') }}</h3>
              </div>
              <SbsHeader />
              <div class="panel-body">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SbsFooter />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SbsNavbar from '@/components/TheNavbar';
import SbsHeader from '@/components/TheHeader';
import SbsFooter from '@/components/TheFooter';
// import { CHECK_AUTH } from './store/actions.type'; // CHECK_AUTH

export default {
  name: 'App',
  components: {
    SbsNavbar,  // actually the sidebar
    SbsHeader,
    SbsFooter,
  },
  computed: {
    ...mapGetters(['currentUser', 'isSideBarVisiable', 'isMigrationOffer']),
    ...mapState({
      authenticated: state => state.auth.user.authenticated,
      isOnboarding: state => state.home.isOnboarding,
      isGatewayLoading: state => state.home.isGatewayLoading,
      isStoreLoading: state => state.actions.isStoreLoading,
      errors: state => state.auth.errors
    })
  },
  mounted() {
  }
};
</script>

<style>
  @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
  @import '../node_modules/ag-grid-community/dist/styles/ag-grid.css';
  @import '../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css';
  body{
    line-height: 1;
    /* overflow-y: hidden; */
    overflow-x: hidden;
    outline: none;
    caret-color: transparent;
  }
  #plexiglass {
    background-color:rgb(122,122,122,0.25) !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    height: 100vh !important;
    width: 100vw !important;
    z-index: 1051 !important;
  }
  #spinner {
      position: fixed !important;
      bottom: 50% !important;
      right: 50% !important;
      color: black !important;
  }
</style>
