<template>
  <div id="feedback" style="margin-top:50vh;" v-if="authenticated==true">
    <div class="row justify-content-center">
        <a role="button" class="p-0" data-toggle="modal" data-target="#theModal" @click="setOpinion(true)">
            <i class="fa fa-thumbs-up fa-1x" aria-hidden="true"></i>
        </a>
        <a role="button" class="pl-3" data-toggle="modal" data-target="#theModal" @click="setOpinion(false);">
            <i class="fa fa-thumbs-down fa-1x" aria-hidden="true"></i>
        </a>
    </div>

    <div class="modal fade" id="theModal" tabindex="-1" role="dialog" aria-labelledby="feedbackModalTitle" aria-hidden="true" data-backdrop="false" style="background-color: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog feedback-modal" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title w-50 text-right" id="feedback-modal-title" v-html="$t('store/view:home:feedback title')"></h5>
                  <h5 class="modal-title w-25 text-center">
                    <span id="displayOpinion" class="fa fa-thumbs-up fa-2x"></span>
                  </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal();">
                <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">

              <div class="input-group">
                  <div class="input-group-prepend">
                      <span class="input-group-text" v-html="$t('store/view:home:feedback prompt')"></span>
                  </div>
                  <textarea id="feedback-message" class="form-control" @keydown.esc="closeModal()"></textarea>
              </div>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModal();" v-html="$t('store/view:home:feedback close')"></button>
              <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitOpinion();" v-html="$t('store/view:home:feedback submit')"></button>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import store from '@/store';
import { mapGetters, mapState } from 'vuex';
import { SUBMIT_FEEDBACK } from '../store/actions.type';
export default {
  name: 'FeedbackModal',
  data() {
    return {
      opinion: false,
      feedback: '',
      account: '',
      person: ''
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      authenticated: state => state.auth.user.authenticated
    })
  },
  methods:{
      setOpinion(val){
        this.opinion = val;
        var span = document.getElementById("displayOpinion");
        if(val){
            span.classList.remove('fa-thumbs-down')
            span.classList.add('fa-thumbs-up');
            span.style.color = "rgb(0, 128, 0)";
        }
        else{
            span.classList.remove('fa-thumbs-up');
            span.classList.add('fa-thumbs-down');
            span.style.color = "rgb(255, 0, 0)";
        }

        window.debugConsole(val);
      },
      closeModal(){
        var feedbackEl = document.getElementById("feedback-message");
        feedbackEl.value = '';
      },
      async submitOpinion(){
          var feedbackEl = document.getElementById("feedback-message");
          var feedbackText = feedbackEl.value;
          feedbackEl.value = '';
          var person = this.currentUser.username;
          var account = this.currentUser.accountId;

          var getUrl = window.location;
          var url = "/" + getUrl.hash;

          // window.debugConsole('Current User Obj: ',this.currentUser);
          // window.debugConsole('URL:', url);
          // window.debugConsole('Feedback: ',feedbackText);
          // window.debugConsole('isGood: ',this.opinion);
        let params = {
            accountID: account,
            personID: person,
            feedback: feedbackText,
            feedbackUrl: url,
            isGood: this.opinion
        };
        // window.debugConsole('params: ', params);
        store.dispatch(SUBMIT_FEEDBACK, params).then((theReturn) => {
            window.debugConsole(theReturn);
        });
      }
  }
};

</script>

<style scoped>
a{
  color: #818181;
}
a:hover {
  color: #f1f1f1;
}
.feedback-modal{
    max-width: 60% !important;
}
</style>