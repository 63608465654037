<template>
  <footer>
    <div id="brand-footer" class="text-center fixed-bottom"><!--&copy; {{getYear()}} <a v-bind:href="brandURL" target="_blank">{{brandID}}</a>--></div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'SbsFooter',
  data() {
    return {
      date: null,
      passwordRecoveryLink: "/",
      // passwordRecoveryLink: "../na/auth/passwordrecovery",
    };
  },
  methods: {
    getYear(){
      return new Date().getFullYear()
    },
  },
  computed: {
    ...mapState({
      brandID: state => state.auth.user.partner_id,
      brandURL: state => state.auth.user.partner_logo_url,   // this is actually the brand home page, never mind the name.
      errors: state => state.auth.errors,
    })
  }
};

</script>
