<template>
    <div class="header text-center col-md-12 mt-5 mb-2" id="brand-logo-container">
      <a v-if="currentUser.logoURL" :href="currentUser.partner_logo_url"  target="_blank">
        <img :src="currentUser.logoURL" width="200" id="brand-logo" class="d-inline" >
      </a>
    </div>
</template>


<script>
import { mapGetters} from 'vuex';
export default {
  name: 'SbsBrandLogo',
  computed: {
    ...mapGetters(['currentUser']),
  },

};
</script>

<style scoped>
/* override default width of logo in partner CSS #brand-logo{width: ?px} */
#brand-logo-container{
  height:64px;
}
a > img{
  cursor:pointer;
}
</style>