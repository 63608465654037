import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   component: () => import('@/views/Home'),
    //   meta: {
    //     breadcrumb: [{ name: 'store/view:home name' }]
    //   }
    // },
    // {
    //   name: 'home',
    //   path: '/home',
    //   meta: {
    //     breadcrumb: [{ name: 'store/view:home name' }]
    //   },
    //   component: () => import('@/views/Home')
    // },
    {
      name: 'site-migration',
      path: '/site-migration',
      component: () => import('@/views/SiteMigration'),
      redirect: {
        name: 'site-migration-offer'
      },
      children: [
        {
          name: 'site-migration-offer',
          path: '/site-migration/offer',
          component: () => import('@/components/migration/MigrationOffer'),
          meta: {
            renderType: 'migration'
          }
        },
        {
          name: 'site-migration-Leg2LatDIY',
          path: '/site-migration/Leg2LatDIY',
          component: () => import('@/components/migration/Leg2LatDIY'),
          meta: {
            renderType: 'migration'
          }
        },
        {
          name: 'site-migration-Leg2LatDIFM',
          path: '/site-migration/DIFM',
          component: () => import('@/components/migration/difm/SiteMigDIFM'),
          redirect: {
            name: 'site-migration-DIFM-base'
          },
          children: [
            {
              name: 'site-migration-DIFM-base',
              path: '/site-migration/DIFM/migrating',
              component: () => import('@/components/migration/difm/BaseStep'),
              meta: {
                renderType: 'migration'
              }
            },
            {
              name: 'site-migration-DIFM-review-iframe',
              path: '/site-migration/DIFM/review',
              component: () => import('@/components/migration/difm/KonvaCanvas'),
              meta: {
                renderType: 'migration'
              }
            },
            {
              name: 'site-migration-DIFM-review-comments',
              path: '/site-migration/DIFM/comment',
              component: () => import('@/components/migration/difm/KonvaCanvas'),
              meta: {
                renderType: 'migration'
              }
            },
            {
              name: 'site-migration-DIFM-feedback',
              path: '/site-migration/DIFM/complete',
              component: () => import('@/components/migration/difm/FeedBack'),
              meta: {
                renderType: 'migration'
              }
            },
          ],
        },
      ],
      // meta: {
      //   breadcrumb: [{ name: 'store/view:dashboard name' }]
      // }
    },
    {
      name: 'dashboard',
      path: '/',
      component: () => import('@/views/Dashboard'),
      // redirect: {
      //   name: 'subscriptions'
      // },
      // children: [
      //   {
      //     name: 'dashboard.uberall',
      //     path: 'uberall',
      //     component: () => import('@/components/UberallDashboard'),
      //     meta: {
      //       breadcrumb: [{ name: 'store/view:dashboard name' }]
      //     },
      //     redirect: {
      //       name: 'uberall.home'
      //     },
      //     children: [
      //       {
      //         name: 'uberall.home',
      //         path: 'home',
      //         component: () => import('@/components/widgets/UberallHome'),
      //         meta: {
      //           breadcrumb: [{ name: 'store/view:dashboard name' }]
      //         },
      //       },
      //     ],
      //   },
      //   {
      //     name: 'dashboard.home',
      //     path: '',
      //     component: () => import('@/components/TheDashboard'),
      //     meta: {
      //       breadcrumb: [{ name: 'store/view:dashboard name' }]
      //     },
      //   },
      // ],
    },
    // {
    //   name: 'manage-persons',
    //   path: '/manage-persons',
    //   meta: {
    //     breadcrumb: [
    //       { name: 'store/view:home name', link: 'home' },
    //     ]
    //   },
    //   component: () => import('@/views/ManagePersons')
    // },
    {
      name: 'subscriptions',
      path: '/subscriptions',
      meta: {
        breadcrumb: [
          { name: 'store/view:home name', link: 'home' },
        ]
      },
      component: () => import('@/views/Subscriptions')
    },
    {
      name: 'home',
      path: '/home',
      meta: {
        breadcrumb: [{ name: 'store/view:home name' }]
      },
      component: () => import('@/views/Dashboard'),
      redirect: {
        name: 'dashboard'
      },
    },
    {
      name: 'multi-product',
      path: '/multi-product',
      meta: {
        breadcrumb: [{ name: 'gateway/view:login:header'}]
      },
      component: () => import('@/components/MultiProduct')
    },
    {
      name: 'login',
      path: '/:brand/login',
      meta: {
        breadcrumb: [{ name: 'gateway/view:login:header'}]
      },
      component: () => import('@/components/EmailForm')
    },
    /* 
      To do: Add product deeplink that accepts plain JSON cargo
      {
        name: 'deep-link',
        path: '/:brand/deeplink/:cargo',
        meta: {
          breadcrumb: [{}]
        },
        redirect: {
          name: 'purchase'
        },
      }
    */
    {
      name: 'login-product',
      path: '/:brand/login/product/:product',
      meta: {
        breadcrumb: [{ name: 'gateway/view:login:header'}]
      },
      component: () => import('@/components/EmailForm')
    },
    {
      name: 'login-domain',
      path: '/:brand/login/domain/:domain/product/:product',
      meta: {
        breadcrumb: [{ name: 'gateway/view:login:header'}]
      },
      component: () => import('@/components/EmailForm')
    },
    {
      name: 'login-password',
      path: '/login/password',
      meta: {
        breadcrumb: [{ name: 'gateway/view:password:header'}]
      },
      component: () => import('@/components/PasswordForm')
    },
    {
      name: 'login-password-recovery',
      path: '/login/password-recovery',
      meta: {
        breadcrumb: [{ name: 'gateway/view:reset_password:header'}]
      },
      component: () => import('@/components/RecoveryForm')
    },
    {
      name: 'login-change-password',
      path: '/login/change-password',
      meta: {
        breadcrumb: [{ name: 'gateway/view:change_password:header'}]
      },
      component: () => import('@/components/ChangePasswordForm')
    },
    {
      name: 'login-password-recovery-failure',
      path: '/login/password-recovery/failure',
      meta: {
        breadcrumb: [{ name: 'gateway/view:reset_password:header', recoveryPhase: 'failed' }]
      },
      component: () => import('@/components/RecoveryForm')
    },
    {
      name: 'registration-verification-code',
      path: '/registration/verification-code',
      meta: {
        breadcrumb: [{ name: 'gateway/view:register:header', registrationPhase: 'verificationCode'}]
      },
      component: () => import('@/components/RegistrationForm')
    },
    {
      name: 'registration-final',
      path: '/registration/final',
      meta: {
        breadcrumb: [{ name: 'gateway/view:register:header', registrationPhase: 'final'}]
      },
      component: () => import('@/components/RegistrationForm')
    },
    {
      name: 'logout',
      path: '/logout',
      component: () => import('@/views/Logout')
    },
    {
      name: 'register-domain',
      path: '/register-domain',
      meta: {
        breadcrumb: [
          { name: 'store/view:home name', link: 'home' },
          { name: 'Register Domain' }
        ]
      },
      component: () => import('@/views/RegisterDomain')
    },
    {
      name: 'view-offer',
      path: '/view-offer/:p3/:action',
      meta: {
        breadcrumb: [
          { name: 'store/view:home name', link: '/home' },
          { name: 'store/view:purchase name' }
        ]
      },
      component: () => import('@/views/ViewOffer'),
      props: true
    },
    {
      name: 'view-offer',
      path: '/view-offer',
      meta: {
        breadcrumb: [
          { name: 'store/view:home name', link: '/home' },
          { name: 'store/view:purchase name' }
        ]
      },
      component: () => import('@/views/ViewOffer'),
      props: true
    },
    {
      name: 'view-stats',
      path: '/view-stats/:domainName/:userid',
      meta: {
        breadcrumb: [
          { name: 'store/view:home name', link: '/home' },
          { name: 'store/view:stats name' }
        ]
      },
      component: () => import('@/views/ViewStats'),
      props: true
    },
    {
      name: 'change-domain',
      path: '/change-domain/:subId',
      meta: {
        breadcrumb: [
          { name: 'store/view:home name', link: '/home' },
          { name: 'store/view:change-domain name' }
        ]
      },
      component: () => import('@/views/DomainManagement'),
      props: true
    },
    {
      name: 'stripe-confirmed',
      path: '/stripe-confirmed/:subData',
      meta: {
        breadcrumb: [ ]
      },
      component: () => import('@/views/StripeConfirmed'),
      props: true
    },
    {
      name: 'stripe-error',
      path: '/stripe-error/:error',
      meta: {
        breadcrumb: [ ]
      },
      component: () => import('@/views/StripeError'),
      props: true
    },
    {
      path: '/@:username',
      component: () => import('@/views/Profile')
      // children: [
      //   {
      //     path: '',
      //     name: 'profile',
      //     component: () => import('@/views/ProfileArticles')
      //   },
      //   {
      //     name: 'profile-favorites',
      //     path: 'favorites',
      //     component: () => import('@/views/ProfileFavorited')
      //   }
      // ]
    }
  ]
});
