import ApiService from '@/common/api.service';
import { CHECK_AUTH, CHECK_IF_SESSION_EXISTS, GET_INFO, EMAIL_SUBMIT, EMAIL_SUBMIT_FOR_RECOVERY, REGISTRATION_SUBMIT, REGISTRATION_VERIFACTION, PASSWORD_SUBMIT, PASSWORD_AUTH, SET_PASSWORD, LOGOUT } from './actions.type';
import i18n from '@/common/i18n';
import Vue from 'vue';
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_CURRENCY,
  SET_ERROR,
  SET_EMAIL,
  SET_JWT_INTERVAL
} from './mutations.type';

const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  JwtInterval: null,
  brandId: null,
  email: null,
  defaultTokenInterval: 300000,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  jwtInterval(state){
    return state.JwtInterval;
  },
  defaultTokenInterval(state){
    return state.defaultTokenInterval;
  },
};

const actions = {
  // [CHECK_AUTH](context, brand) {
  //   if(typeof brand !== 'undefined'){
  //     this.brandId = brand;
  //   }
  //   return new Promise((resolve, reject) => {
  //     ApiService.query(this.brandId + '/auth/authenticate')
  //       .then(({ data }) => {
  //         window.debugConsole('success after check session' + data);
  //         context.commit(SET_AUTH, data.user);
  //         context.commit(SET_CURRENCY, data.user.currency);
  //         context.commit(SET_BRAND, data.user.partner_id);
  //         resolve(data.user);
  //       })
  //       .catch(({ response }) => {
  //         window.debugConsole('failed ' + response);
  //         context.commit(SET_ERROR, i18n.t('store/auth failed'));
  //         reject(i18n.t('store/auth failed'));
  //       });
  //   });
  // },
  [CHECK_IF_SESSION_EXISTS](){
    return new Promise((resolve, reject) => {
      ApiService.query('na/auth/remembered')
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      ApiService.query('na/auth/token')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, i18n.t('store/auth failed'));
          reject(i18n.t('store/auth failed'));
        });
    });
  },
  [GET_INFO](context, brand = 'na') {
    var brandToUse = (brand == 'na')
    ? (Vue.$cookies.get('partner_id') !== null
        ? Vue.$cookies.get('partner_id')
        :  'na')
    : brand;
    return new Promise((resolve, reject) => {
      ApiService.query(brandToUse + '/auth/get-info')
        .then(({ data }) => {
          window.debugConsole('success after check session' + data);
          context.commit(SET_AUTH, data.user);
          context.commit(SET_CURRENCY, data.user.currency);

          var showAnalytics = data.user.ssi_ga_analytics_id;
          if (showAnalytics && showAnalytics.trim() !== "") {

            // Ensure we're not doubling up
            const existingGtmScript = document.querySelector(`script[src*="${showAnalytics}"]`);
            if(!existingGtmScript){
              // Add the first part of Google Tag Manager (script) to the head section with the specified ID
              const gtmScript = document.createElement("script");
              gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${showAnalytics}');`;
              document.head.appendChild(gtmScript);
            }

            // Ensure we're not doubling up
            // Check if the GTM noscript iframe with the specified ID exists in the body
            const existingGtmIframe = document.getElementById(`gtmNoscript_${showAnalytics}`);

            if (!existingGtmIframe) {
              // Add the second part of Google Tag Manager (noscript) just below the opening body tag with the specified ID
              const gtmNoscript = document.createElement("noscript");
              gtmNoscript.id = `gtmNoscript_${showAnalytics}`; // Set the ID for the iframe
              gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${showAnalytics}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
              document.body.insertBefore(gtmNoscript, document.body.firstChild);
            }

          }

          resolve(data.user);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, i18n.t('store/auth failed'));
          reject(i18n.t('store/auth failed'));
        });
    });
  },
  [PASSWORD_AUTH](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('na/auth/validatepassword', { user: credentials }, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
        .then(({ data }) => {
          window.debugConsole('success after validatePassword' + data);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, i18n.t('store/view:confirm_purchase password_invalid')
          );
          reject(i18n.t('store/view:confirm_purchase password_invalid'));
        });
    });
  },
  [EMAIL_SUBMIT](context, credentials) {
    var partner_id = context.rootState.auth.user.partner_id;
    var username = btoa(credentials.email);
    var rememberMe = credentials.rememberMe;
    context.rootState.auth.user.username = credentials.email;
    window.debugConsole("In EMAIL_SUBMIT");
    context.commit(SET_EMAIL, credentials.email);
    return new Promise((resolve, reject) => {
      ApiService.post(partner_id + '/auth/login', {"LoginForm":{'id': username, 'rememberMe': rememberMe}}, {
        'X-CSRF-Token': context.rootState.auth.user.token
      }) // { user: credentials }
        .then(({ data }) => {
          window.debugConsole('success after emailSubmit' + data);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, response.data.error
          );
          reject(i18n.t('store/view:confirm_purchase password_invalid'));
        });
    });
  },
  [EMAIL_SUBMIT_FOR_RECOVERY](context, credentials) {
    var partner_id = context.rootState.auth.user.partner_id;
    var username = btoa(credentials.email);

    return new Promise((resolve, reject) => {
      ApiService.post(partner_id + "/auth/passwordrecovery", {"PasswordRecoveryForm":{'email': username}},{
        'X-CSRF-Token': context.rootState.auth.user.token
      }) // { user: credentials }
        .then(({ data }) => {
          window.debugConsole('success after emailSubmit' + data);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, response.data.error
          );
          reject();
        });
    });
  },
  [PASSWORD_SUBMIT](context, credentials) {
    var partner_id = context.rootState.auth.user.partner_id;
    var username = btoa(credentials.email);
    var password = btoa(credentials.password);
    return new Promise((resolve, reject) => {
      ApiService.post(partner_id + "/auth/password", {"PasswordForm":{'username':username, 'password': password}}, {
        'X-CSRF-Token': context.rootState.auth.user.token
      }) // { user: credentials }
        .then(({ data }) => {
          window.debugConsole('success after passwordSubmit' + JSON.stringify(data));
          context.commit(SET_AUTH, data.user);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, response.data.error
          );
          reject(i18n.t('store/view:confirm_purchase password_invalid'));
        });
    });
  },
  [SET_PASSWORD](context, credentials) {
    var partner_id = context.rootState.auth.user.partner_id;
    var password = credentials.password ? btoa(credentials.password) : '';
    var passwordAgain = credentials.passwordAgain ? btoa(credentials.passwordAgain) : '';
    return new Promise((resolve, reject) => {
      ApiService.post(partner_id + "/auth/changeownpassword", {"ChangeOwnPasswordForm":{'password': password, 'repeat_password': passwordAgain}}, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
        .then(({ data }) => {
          window.debugConsole('success after changeownpassword' + JSON.stringify(data));
          if(data && data.user && data.user.user !== undefined ? data.user.user : false){
            context.commit(SET_AUTH, data.user.user);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, response.data.error
          );
          reject(i18n.t('store/view:confirm_purchase password_invalid'));
        });
    });
  },
  [REGISTRATION_VERIFACTION](context, credentials) {
    var partner_id = context.rootState.auth.user.partner_id;
    window.debugConsole("creds: " + JSON.stringify(credentials));
    var username = btoa(credentials.email);
    var verificationCode = credentials.verificationCode;
    window.debugConsole("Verification code middle");
    return new Promise((resolve, reject) => {
      ApiService.post(partner_id + "/site/verifycode", {"VerifyEmailForm":{'email':username, 'verification_code': verificationCode}}, {
        'X-CSRF-Token': context.rootState.auth.user.token
      }) // { user: credentials }
        .then(({ data }) => {
          window.debugConsole('success after registrationVerifaction' + JSON.stringify(data));
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, response.data.error
          );
          reject(i18n.t('store/view:confirm_purchase password_invalid'));
        });
    });
  },
  [REGISTRATION_SUBMIT](context, credentials) {
    var partner_id = context.rootState.auth.user.partner_id;
    window.debugConsole("partner_id before reg_sub: " + partner_id);
    return new Promise((resolve, reject) => {
      ApiService.post(partner_id + "/auth/finalregistration", credentials, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ', response);
          context.commit(
            SET_ERROR, response.data.error
          );
          reject(i18n.t('store/view:confirm_purchase password_invalid'));
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
  },
  [SET_JWT_INTERVAL](state, payload) {
    state.JwtInterval = payload;
  },
  [SET_EMAIL](state, email){
    state.email = email;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
