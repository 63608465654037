import {
    UPDATE_ONBOARDING_TOOLTIP
} from './mutations.type';

const genericMutations = {
    [UPDATE_ONBOARDING_TOOLTIP] (state, tooltip) {
        const { initial_store_onboarding_tooltips } = state;
        if (tooltip in initial_store_onboarding_tooltips) {
            state.active_onboarding_tooltip.id = tooltip;
            state.active_onboarding_tooltip.data = initial_store_onboarding_tooltips[tooltip].data;
            state.active_onboarding_tooltip.options = initial_store_onboarding_tooltips[tooltip].options;
        }else{
            state.active_onboarding_tooltip = {};
        }
    }
}

export default genericMutations