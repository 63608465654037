import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
//import createPersistedState from 'vuex-persistedstate';

import home from './home.module';
import auth from './auth.module';
import actions from './action.module';

import genericModule from './generic/index';

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  // plugins: [createPersistedState()],
  modules: {
    home,
    auth,
    actions,
    generic: genericModule
  }
});
