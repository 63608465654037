export const SET_PARTNER = 'setPartner';
export const UPDATE_DATA_IN_GRID = 'updateDataInGrid';
export const UPDATE_APP_STATE = 'updateAppState';
export const SET_PLANS = 'setPlans';
export const SET_PCATS = 'setPcats';
export const SET_SUBSCRIBED_CORE_PCATS = 'setSubCorePcats';
export const FETCH_START = 'setLoading';
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_CURRENCY = 'setCurrency';
export const SET_ERROR = 'setError';
export const SET_PROFILE = 'setProfile';
export const SET_USERS_DATA = 'setUsersData';
export const SET_OFFER_DATA = 'setOfferData';
export const SET_NEW_USER_ID = 'setNewUserId';
export const SET_NEW_SUB = 'setNewSub';
export const RESET_STATE = 'resetState';
export const SET_COUNTRIES = 'setCountries';
export const SET_OPINION = 'setOpinion';
export const SET_DEEP_LINKED_FOLLOWED = "setDeepLinkedFollowed";

export const SET_ONBOARDING_STATE = 'setOnboardingState';
export const SET_GATEWAY_LOADING_STATE = 'setGatewayLoadingState';
export const SET_SIDE_BAR_STATE = 'setSideBarState';
export const SET_MIGRATION_OFFER_STATE = 'setMigrationOfferState';
export const SET_MIGRATION_STAGE_STATE = 'setMigrationStageState';
export const SET_CURRENT_DIFM_STEP = 'setCurrentDifmStep';
export const SET_DIFM_STEPS = 'setDifmSteps';
export const CHECK_DEEP_LINK = 'checkDeepLink';
export const SET_WIDGETS = 'setWidgets';
export const SET_INACTIVE_WIDGETS = 'setInactiveWidgets';
export const SET_INACTIVE_GRAPHS = 'setInactiveGraphs';
export const SET_PERSONS = 'setPersons';
export const SET_STORE_LOADING_STATE = 'setStoreLoadingState';
export const SET_DEEP_LINK = 'setDeepLink';
export const SET_WARN = 'setWarn';
export const SET_PERSON_DETAIL = 'setPersonDetail';
export const SET_PERSON_ONE = 'setPersonOne';
export const SET_STATGRAPHS = 'setStatGraphs';
export const SET_DIFM_STEP_DETAIL = 'setDifmStepDetail';
export const SET_SITEMIG_IMG_URLS = "setsitemigImgUrls";
export const SET_JOB_FEEDBACK = "setJobFeedback";
export const SET_JWT_INTERVAL = "setJwtInterval";
export const SET_BRAND = "setBrand";
export const SET_EMAIL = "setEmail";
export const SET_PA_JOB_DOMAIN = "setPaJobDomain";
