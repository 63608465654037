import ApiService from '@/common/api.service';
import i18n from '@/common/i18n';

import {
  UPDATE_USER,
  PURCHASE,
  STRIPE_CONFIRM_PURCHASE,
  CREATE_USER_IF_NEEDED,
  CREATE_USER_ID,
  CREATE_DOMAIN_IF_NEEDED,
  GET_DOMAIN_INFO,
  EDIT_SUB,
  CANCEL_SUB,
  RESTART_SUB,
  SSO,
  VENDOR_SSO,
  FETCH_USER,
  SUBMIT_FEEDBACK,
  FETCH_REGISTRATION_REQUIREMENTS,
  GET_MULTI_PRODUCT_DATA,
  GET_SKU_SALES_ACTION,

  //Widget Actions
  ACTIVATE_WIDGET,
  DEACTIVATE_WIDGET,
  FETCH_WIDGETS,
  FETCH_INACTIVE_WIDGETS,
  UPDATE_WIDGETS,
  FETCH_STATGRAPHS,
  FETCH_INACTIVE_GRAPHS,
  ENSURE_WIDGET_USER,
  CREATE_WIDGET_USER,
  CHECK_UBER_SUB,
  // SET_GATEWAY_LOADING,

  //Dashboard Actions
  SET_STORE_LOADING,

  //Person Actions
  GET_PERSONS,
  CREATE_PERSON,
  DELETE_PERSON,
  UPDATE_PERSON,
  SUSPEND_PERSON,
  // GET_PERSON_ONE_GUID,
  FETCH_LANGUAGE_DROPDOWN,
} from './actions.type';
import {
  SET_ERROR,
  SET_NEW_SUB,
  SET_NEW_USER_ID,
  SET_PERSONS,
  SET_STORE_LOADING_STATE,
  SET_WARN,
  SET_PERSON_DETAIL,
  SET_WIDGETS,
  SET_INACTIVE_WIDGETS,
  SET_PERSON_ONE,
  SET_STATGRAPHS,
  SET_INACTIVE_GRAPHS,
} from './mutations.type';

const initialState = {
  createdUserId: '',
  createdDomain: '',
  newSub: '',
  domainInfo: [],
  widgetColumns: [],
  statGraphs: [],
  inactiveWidgets: [],
  inactiveGraphs: [],
  persons: [],
  personDetail: [],
  isStoreLoading: 0,
  personOne: '',
  warn : {
    id : null,
    title : '',
    message : '',
    submit : '',
    person : {
      firstName: '',
      lastName: '',
      id: '',
      email: ''
    }
  },
  hasUberSub: '',
  languageDropdown: [],
};

export const state = { ...initialState };

const getters = {
  hasUberSub(state){
    return state.hasUberSub;
  },
  newSub(state) {
    return state.newSub;
  },
  widgets(state) {
    return state.widgetColumns;
  },
  statGraphs(state) {
    return state.statGraphs;
  },
  inactiveWidgets(state) {
    return state.inactiveWidgets;
  },
  inactiveGraphs(state) {
    return state.inactiveGraphs;
  },
  persons(state){
    return state.persons;
  },
  warn(state){
    return state.warn;
  },
  personDetail(state){
    return state.personDetail;
  },
  personOne(state){
    return state.personOne;
  },
  isStoreLoading(state){
    return state.isStoreLoading;
  },
  languageDropdown(state){
    return state.languageDropdown;
  }
};

const actions = {
  [GET_MULTI_PRODUCT_DATA](context){
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/fetchmultiproductdata')
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(FETCH_LANGUAGE_DROPDOWN + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  [GET_SKU_SALES_ACTION](context, productID3){
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/get-sku-sales-action?productID3=' + productID3)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  [FETCH_LANGUAGE_DROPDOWN](context){
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/fetchlanguagedropdown')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            context.commit('setLanguageDropdown', data.results);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(FETCH_LANGUAGE_DROPDOWN + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  [SET_STORE_LOADING](context, state) {
    context.commit(SET_STORE_LOADING_STATE, state);
  },
  [CHECK_UBER_SUB](context) {
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/checkubersub')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            context.commit('setUberSub', data.uberSub);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(CHECK_UBER_SUB + 'failed ', response);

            //This line is here only for example purposes and has to be removed before prod
            context.commit('setUberSub', true);

            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [CREATE_WIDGET_USER](context){
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/createwidgetuser')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(data);
            resolve(data.results);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(CREATE_WIDGET_USER + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [ENSURE_WIDGET_USER](context){
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/fetchwidgetuser')
          .then(({ data }) => {
            if(data.results.length){
              console.log('data length :' + data.results.length);
              console.log('data :' + data.results);
              context.dispatch(FETCH_STATGRAPHS);
              context.dispatch(FETCH_WIDGETS);
              context.commit(SET_STORE_LOADING_STATE, 0);
              resolve(data.results);
            } else {
              context.dispatch(CREATE_WIDGET_USER).then(({data}) => {
                context.dispatch(FETCH_STATGRAPHS);
                context.dispatch(FETCH_WIDGETS);
                context.commit(SET_STORE_LOADING_STATE, 0);
                resolve(data.results);
              })
                  .catch(({ response }) => {
                    window.debugConsole(ENSURE_WIDGET_USER + ' failed ', response);
                    context.commit(SET_STORE_LOADING_STATE, 0);
                    context.commit(
                        SET_ERROR, response.data.ref
                    );
                    reject();
                  });
            }
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(ENSURE_WIDGET_USER + ' failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [GET_PERSONS](context){
    let accountID = context.rootState.auth.user.accountId;
    // context.commit(SET_STORE_LOADING_STATE, 1);
    // context.commit(SET_GATEWAY_LOADING, true);

    const { data } = await ApiService.yiiQuery(
        'accounts/' + accountID + '/persons'
    ).catch(() => {
      // context.commit(SET_STORE_LOADING_STATE, 0);
      // context.commit(SET_GATEWAY_LOADING, false);
    });
    var person1Id = await ApiService.query(
        'na/site/personone?person=' + accountID
    ).catch(() => {
      // context.commit(SET_STORE_LOADING_STATE, 0);
      // context.commit(SET_GATEWAY_LOADING, false);
    });
    var persons = [];
    if (data.results.detail){
      data.results.detail.persons.forEach(p => {
        if(p.status == 1 || p.status == 3){
          persons.push(p);
        }
      });
    }
    context.commit(SET_PERSON_ONE, (person1Id.data.results) ? person1Id.data.results[0]['person_id']: '');
    context.commit(SET_PERSONS, persons);
    // context.commit(SET_STORE_LOADING_STATE, 0);
    // context.commit(SET_GATEWAY_LOADING, false);
  },
  async [CREATE_PERSON](context, request){
    context.commit(SET_STORE_LOADING_STATE, 1);
    request['accountId'] = context.rootState.auth.user.accountId;
    return new Promise((resolve, reject) => {
      ApiService.post('na/site/inviteperson', request, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(CREATE_PERSON + 'success adding person' + data);
            context.commit(SET_PERSONS, null);
            context.dispatch(GET_PERSONS);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(CREATE_PERSON + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [UPDATE_PERSON](context, request){
    context.commit(SET_STORE_LOADING_STATE, 1);
    request['accountID'] = context.rootState.auth.user.accountId;
    var resource = `accounts/${request.accountID}/persons/${request.personID}`;
    return new Promise((resolve, reject) => {
      ApiService.yiiPut(resource, request, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(UPDATE_PERSON + 'success updating person' + data);
            context.commit(SET_PERSONS, null);
            context.dispatch(GET_PERSONS);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(UPDATE_PERSON + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [SUSPEND_PERSON](context, request){
    request['accountID'] = context.rootState.auth.user.accountId;
    context.commit(SET_STORE_LOADING_STATE, 1);
    var resource = `accounts/${request.accountID}/persons/${request.personID}/suspend`;
    return new Promise((resolve, reject) => {
      ApiService.yiiPut(resource, {}, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(SUSPEND_PERSON +'success suspending person' + data);
            context.commit(SET_PERSONS, null);
            context.dispatch(GET_PERSONS);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(SUSPEND_PERSON + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  [DELETE_PERSON](context, request){
    context.commit(SET_STORE_LOADING_STATE, 1);
    request['accountID'] = context.rootState.auth.user.accountId;
    var resource = `accounts/${request.accountID}/persons/${request.personID}`;
    return new Promise((resolve, reject) => {
      ApiService.yiiDelete(resource, request, {
        'X-CSRF-Token': context.rootState.auth.user.token
      })
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            context.commit(SET_PERSONS, null);
            context.dispatch(GET_PERSONS);
            window.debugConsole(DELETE_PERSON + 'success deleting person' + data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(DELETE_PERSON + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [FETCH_STATGRAPHS](context) {
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/fetchstatgraphs')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            var filtered = [];
            data.results.forEach(graph => {
              // if(!graph.sub_req  || (graph.sub_req == 'uberall' && state.hasUberSub))
              filtered.push(graph);
            });
            context.commit(SET_STATGRAPHS, (filtered.length) ? filtered : false);
            window.debugConsole(FETCH_STATGRAPHS + 'success getting statgraphs' + data);
            resolve(filtered);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(FETCH_STATGRAPHS + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [FETCH_WIDGETS](context) {
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/fetchwidgets')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            var columns = [
              {id: 0, size: 'col-12', group: "{name: 'widgets', pull: false, put: false}", widgets : []},
              {id: 1, size: 'col', group: "{name: 'widgets', pull: true, put: true}", widgets : []},
              {id: 2, size: 'col', group: "{name: 'widgets', pull: true, put: true}", widgets : []},
            ];
            data.results.forEach(widget => {
              // if(!widget.sub_req  || (widget.sub_req == 'uberall' && state.hasUberSub)){
              if(widget.component == 'StatGraph')
                columns[0].widgets.push(widget);
              else if(widget.order_set % 2 != 0){
                columns[1].widgets.push(widget);
              }else{
                columns[2].widgets.push(widget);
              }
              // }
            });
            // columns.forEach(column => {
            //   if(!column.widgets.length)
            //     column.widgets.push({component: "EmptyColumn"});
            // });
            context.commit(SET_WIDGETS, columns);
            resolve(data.results);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(FETCH_WIDGETS + ' failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [DEACTIVATE_WIDGET](context, component) {
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((reject) => {
      ApiService.query(`na/site/deactivatewidget?component=${component}`)
          .then(() => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            context.dispatch(FETCH_WIDGETS);
            context.dispatch(FETCH_STATGRAPHS);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(DEACTIVATE_WIDGET + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [ACTIVATE_WIDGET](context, id){
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((reject) => {
      ApiService.query(`na/site/activatewidget?id=${id}`)
          .then(() => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            context.dispatch(FETCH_STATGRAPHS);
            context.dispatch(FETCH_WIDGETS);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(ACTIVATE_WIDGET + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [FETCH_INACTIVE_WIDGETS](context) {
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((reject) => {
      ApiService.query('na/site/fetchinactivewidgets')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            var res = [];
            data.results.forEach(widget => {
              // if(!widget.sub_req  || (widget.sub_req == 'uberall' && state.hasUberSub))
              res.push(widget);
            });
            context.commit(SET_INACTIVE_WIDGETS, res);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(FETCH_INACTIVE_WIDGETS + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [FETCH_INACTIVE_GRAPHS](context) {
    context.commit(SET_STORE_LOADING_STATE, 1);
    return new Promise((reject) => {
      ApiService.query('na/site/fetchinactivegraphs')
          .then(({ data }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            var res = [];
            data.results.forEach(graph => {
              // if(!graph.sub_req  || (graph.sub_req == 'uberall' && state.hasUberSub))
              res.push(graph);
            });
            context.commit(SET_INACTIVE_GRAPHS, res);
          })
          .catch(({ response }) => {
            context.commit(SET_STORE_LOADING_STATE, 0);
            window.debugConsole(FETCH_INACTIVE_GRAPHS + 'failed ', response);
            context.commit(
                SET_ERROR, response.data.ref
            );
            reject();
          });
    });
  },
  async [UPDATE_WIDGETS](widgets) {
    var current = [];
    widgets.forEach(column => {
      column.widgets.forEach((widget, i) => {
        i += 1;
        if (column.id != 0)
          widget.order_set = (column.id == 1) ? ((i == 1) ? 1 : ((i * 2) - 1)) : (i * 2);
        else
          widget.order_set = 0;
        if(widget.component != 'EmptyColumn')
          current.push(widget);
      });
    });

    var req = JSON.stringify(current);
    return new Promise(() => {
      ApiService.query(`na/site/updatewidgets?current=${req}`);
    });
  },
  async [FETCH_REGISTRATION_REQUIREMENTS]() {
    return new Promise((resolve) => {
      ApiService.query('na/site/fetchregistrationdata')
          .then(({ data }) => {
            resolve(data);
          });
    });
  },
  async [CREATE_USER_ID](context, data) {
    let accountId = data.account.accountId;
    let newuserid = '';
    for (let i = 1; i < 30; i++) {
      newuserid = accountId + '_' + i;
      if (data.userIds.indexOf(newuserid) == -1) {
        this.createdUserId = newuserid;
        return;
      } else {
        continue;
      }
    }
  },
  [FETCH_USER](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.yiiQuery(`users/${request.userid}`)
          .then(({ data }) => {
            window.debugConsole('got success' + data);
            if (data.results && data.results.code != '200') {
              window.debugConsole('got error in getting user details' + data.results);
              reject(
                  '[Ref:' +
                  data.results.ref +
                  '] ' +
                  i18n.t('store/view:stats error_user_get_details')
              );
            }
            resolve(data.results.detail);
          })
          .catch(({ response }) => {
            window.debugConsole('got error' + response);
          });
    });
  },
  [GET_DOMAIN_INFO](context, request) {
    return new Promise((resolve, reject) => {
      ApiService.yiiQuery(
          'users/' + request.userid + '/domains/' + request.domain + '/info'
      )
          .then(({ data }) => {
            window.debugConsole('got success' + data);
            if (data.results && data.results.code != '202') {
              window.debugConsole('got error in verify domain' + data.results);
              context.commit(
                  SET_ERROR,
                  '[Ref:' +
                  data.results.ref +
                  '] ' +
                  i18n.t('store/view:domain domain_verify_error')
              );
              reject(
                  SET_ERROR,
                  '[Ref:' +
                  data.results.ref +
                  '] ' +
                  i18n.t('store/view:domain domain_verify_error')
              );
            }
            resolve(data.results.detail);
          })
          .catch(({ response }) => {
            window.debugConsole('got error' + response);
            context.commit(
                SET_ERROR,
                '[Ref:' +
                response.data.ref +
                '] ' +
                i18n.t('store/view:domain domain_verify_error')
            );
          });
    });
  },
  verifyDomainDns(context, domain){
    return new Promise((resolve, reject) => {
      ApiService.query(`na/site/verify-domain-dns?domain=` + domain)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  purchaseDomain(){
    return new Promise((resolve, reject) => {
      ApiService.query(`na/site/purchase-domain`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  getPurchaseDomainInfo(){
    return new Promise((resolve, reject) => {
      ApiService.query(`na/site/domain-purchase-info`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  triggerDomainInfo(){
    return new Promise((resolve, reject) => {
      ApiService.query(`na/site/trigger-domain-info`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  },
  async [CREATE_DOMAIN_IF_NEEDED](context, request) {
    if (request.needsDomain) {
      if (request.domain) {
        this.createdDomain = request.domain;
        return;
      } else {
        const { data } = await ApiService.yiiQuery(
            'accounts/' + context.rootState.auth.user.accountId + '/domain/name'
        );
        window.debugConsole('res domain is ', data);
        if (data.results && data.results.code != '200') {
          window.debugConsole('got error in create domain' + data.results);
          throw Error(
              '[Ref:' +
              data.results.ref +
              '] ' +
              i18n.t('store/sub:purchase domain_add_error')
          );
        } else {
          this.createdDomain = data.results.domain_name;
        }
      }
    } else {
      this.createdDomain = '';
    }
  },
  async [CREATE_USER_IF_NEEDED](context, request) {
    window.debugConsole('got data', request);
    let userId = '';
    if (request.userid) {
      this.createdUserId = userId = request.userid;
    } else {
      await context.dispatch(CREATE_USER_ID, {
        userIds: context.rootState.home.userIds,
        account: context.rootState.auth.user
      });
      userId = this.createdUserId;
      await context.dispatch(CREATE_DOMAIN_IF_NEEDED, request);
      let domain = this.createdDomain;
      const { data } = await ApiService.yiiPost('users', {
        userID: userId,
        domain: domain,
        accountID: context.rootState.auth.user.accountId
      }, {
        'X-CSRF-Token': context.rootState.auth.user.token
      });
      if (data.results && data.results.code != '201') {
        window.debugConsole('got error in create user' + data.results);
        throw Error(
            '[Ref:' +
            data.results.ref +
            '] ' +
            i18n.t('store/sub:purchase domain_add_error')
        );
      } else {
        window.debugConsole('lets store userid' + userId);
        context.commit(SET_NEW_USER_ID, userId);
      }
    }
  },
  async [UPDATE_USER](context, request) {
    const { data } = await ApiService.yiiPut(
        'users/' + request.userid,
        request.cargo
    );
    if (data.results && data.results.code != '201') {
      window.debugConsole('got error in update user with domain', data.results);
      context.commit(
          SET_ERROR,
          '[Ref:' +
          data.results.ref +
          '] ' +
          i18n.t('store/sub:purchase domain_update_error')
      );
      throw Error(
          '[Ref:' +
          data.results.ref +
          '] ' +
          i18n.t('store/sub:purchase domain_update_error')
      );
    }
  },
  async [PURCHASE](context, request) {
    await context.dispatch(CREATE_USER_IF_NEEDED, request);
    // double check id domain exit before moving ahead
    if (request.needsDomain && !request.domain && !this.createdDomain) {
      await context.dispatch(CREATE_DOMAIN_IF_NEEDED, request);
      await context.dispatch(UPDATE_USER, {
        userid: this.createdUserId,
        cargo: { domain: this.createdDomain }
      });
    }
    let postData = {
      planID: request.planID
    };

    postData.stripeStepData = {
      'needsDomain': request.needsDomain,
      'domain': request.domain ? request.domain : this.createdDomain,
      'createdUserId': this.createdUserId,
    };
    if (!request.needsDomain && request.quantity) {
      postData.quantity = request.quantity;
    }
    if (request.campID) {
      postData.campID = request.campID;
      //postData.currency = context.rootState.home.currency;
    }
    if(request.countryCode && request.countryCode !== ''){
      postData.countryCode = request.countryCode;
    }
    postData.currency = context.rootState.home.currency;
    window.debugConsole(PURCHASE + ' post data: ' + JSON.stringify(postData));

    const { data } = await ApiService.yiiPost(
        'users/' + this.createdUserId + '/subscriptions',
        postData, {
          'X-CSRF-Token': context.rootState.auth.user.token
        }
    );

    window.debugConsole(PURCHASE + ' response data: ' + JSON.stringify(data));

    if(data.results !== undefined && data.results.url !== undefined){
      // TODO put this in an iframe interrupt so you can continue the flow after checkout?
      window.location = data.results.url;
    }else {
      if (data.results && data.results.code == '201') {
        context.commit(SET_NEW_SUB, data.results.detail);
      }
      else {
        var errorCode = 'store/sub:purchase error'; // Default code
        if(data.results.code == 409 && data.results.detail.message !== undefined && data.results.detail.message.includes('Cannot set plan to')){
          errorCode = 'store/sub:purchase:custom:domain error';
        }
        window.debugConsole('got error in purchase', data.results);
        context.commit(
            SET_ERROR,
            '[Ref:' + data.results.ref + '] ' + i18n.t(errorCode)
        );
        throw Error(
            '[Ref:' + data.results.ref + '] ' + i18n.t(errorCode)
        );
      }
    }
  },
  async [STRIPE_CONFIRM_PURCHASE](context, request) {

    window.debugConsole(PURCHASE + ' post data: ' + JSON.stringify(request));
    request.stripeConfirmed = true;

    const { data } = await ApiService.yiiPost(
        'users/' + request.stripeStepData.createdUserId + '/subscriptions',
        request, {
          'X-CSRF-Token': context.rootState.auth.user.token
        }
    );

    window.debugConsole(PURCHASE + ' response data: ' + JSON.stringify(data));

    if (data.results && data.results.code == '201') {
      context.commit(SET_NEW_SUB, data.results.detail);
    } else {
      window.debugConsole('got error in purchase', data.results);
      context.commit(
          SET_ERROR,
          '[Ref:' + data.results.ref + '] ' + i18n.t('store/sub:purchase error')
      );
      throw Error(
          '[Ref:' + data.results.ref + '] ' + i18n.t('store/sub:purchase error')
      );
    }

  },
  async [SUBMIT_FEEDBACK](context, request) {
    return new Promise((resolve, reject) => {
      let putData = {
        page: request.feedbackUrl,
        feedback: request.feedback,
        isGood: request.isGood,
      };

      ApiService.yiiPost(
          'accounts/' + request.accountID + '/persons/' + request.personID + '/feedback',
          putData, {
            'X-CSRF-Token': context.rootState.auth.user.token
          }
      ).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [EDIT_SUB](context, request) {
    let putData = {
      planID: request.planID
    };
    if (!request.needsDomain && request.quantity) {
      putData.quantity = request.quantity;
    }
    putData.currency = context.rootState.home.currency;

    window.debugConsole(EDIT_SUB + ' request data: ' + JSON.stringify(putData));

    const { data } = await ApiService.yiiPut(
        'users/' + request.userid + '/subscriptions/' + request.subId,
        putData, {
          'X-CSRF-Token': context.rootState.auth.user.token
        }
    );

    window.debugConsole(EDIT_SUB + ' response data: ' + JSON.stringify(data));

    if(data.results !== undefined && data.results.url !== undefined){
      window.location = data.results.url;
    } else {
      if (data.results && data.results.code != '201') {
        window.debugConsole('got error in edit sub' + data.results);
        context.commit(
            SET_ERROR,
            '[Ref:' +
            data.results.ref +
            '] ' +
            i18n.t('store/sub:change error')
        );
      } else{
        context.commit(SET_NEW_SUB, data.results.detail);
      }
    }
  },
  [CANCEL_SUB](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.yiiPut(
          'users/' + data.userid + '/subscriptions/' + data.subId + '/nonrenew', {
            'X-CSRF-Token': context.rootState.auth.user.token
          }
      )
          .then(({ data }) => {
            window.debugConsole('got success' + data);
            if (data.results && data.results.code != '201') {
              window.debugConsole('got error in cancel sub' + data);
              context.commit(
                  SET_ERROR,
                  '[Ref:' +
                  data.results.ref +
                  '] ' +
                  i18n.t('store/sub:cancel error')
              );
              reject(
                  '[Ref:' +
                  data.results.ref +
                  '] ' +
                  i18n.t('store/sub:cancel error')
              );
            }
            resolve(data.results);
          })
          .catch(({ response }) => {
            window.debugConsole('got error' + response);
            context.commit(
                SET_ERROR,
                '[Ref:' +
                response.data.ref +
                '] ' +
                i18n.t('store/sub:cancel error')
            );
          });
    });
  },
   async [RESTART_SUB](context, request) {
        window.debugConsole('what request ' + JSON.stringify(request));

        let restartData = {
        };

        let restartSubResponse = await ApiService.yiiPut(
            'users/' + request.userid + '/subscriptions/' + request.subId + '/reactivate',
            restartData, {
                'X-CSRF-Token': context.rootState.auth.user.token
            }
        );

        let data = restartSubResponse.data;

        window.debugConsole('got response in restart sub' + JSON.stringify(data));

      if(data.results !== undefined && data.results.url !== undefined){
          window.debugConsole(" URL " + data.results.url);
          window.location.href = "" + data.results.url;
          this.$router.push(data.results.url);
          return data.results.url;
      } else {
          window.debugConsole(" NO URL " + JSON.stringify(data.results));
          if (data.results && data.results.code != '201') {
          window.debugConsole('got error in reactivate sub' + data.results);
          context.commit(
              SET_ERROR,
              '[Ref:' +
              data.results.ref +
              '] ' +
              i18n.t('store/sub:restart error')
          );
        }
        context.commit(RESTART_SUB, data.results.detail);
      }
  },
  [SSO](context, data) {
    return new Promise((resolve, reject) => {
      let apiUrl = '';
      if (data.subId) {
        apiUrl =
            'users/' + data.userid + '/subscriptions/' + data.subId + '/sso';
      } else {
        apiUrl = 'users/' + data.userid + '/' + data.ssoAction;
      }
      ApiService.yiiQuery(apiUrl, {
        person: context.rootState.auth.user.username
      })
          .then(({ data }) => {
            window.debugConsole('got success', data);
            if (data.results.code == 303) {
              resolve(data.headers.location || data.headers.Location);
            } else {
              context.commit(
                  SET_ERROR,
                  '[Ref:' + data.results.ref + '] ' + i18n.t('store/sub:sso error')
              );
              reject(
                  '[Ref:' + data.results.ref + '] ' + i18n.t('store/sub:sso error')
              );
            }
          })
          .catch(({ response }) => {
            window.debugConsole('got error' + response);
            context.commit(
                SET_ERROR,
                '[Ref:' +
                response.data.results.ref +
                '] ' +
                i18n.t('store/sub:sso error')
            );
            reject(
                '[Ref:' +
                response.data.results.ref +
                '] ' +
                i18n.t('store/sub:sso error')
            );
          });
    });
  },
  [VENDOR_SSO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query(
          'users/' +
          data.userid +
          '/' +
          data.pcat.ssoAction +
          '/' +
          data.pcat.ssoSuffixNew
      )
          .then(({ data }) => {
            window.debugConsole('got success' + data);
            if (data.results.code == 303) {
              resolve(data.headers.location || data.headers.Location);
            } else {
              context.commit(
                  SET_ERROR,
                  '[Ref:' + data.results.ref + '] ' + i18n.t('store/sub:sso error')
              );
              reject(
                  SET_ERROR,
                  '[Ref:' + data.results.ref + '] ' + i18n.t('store/sub:sso error')
              );
            }
          })
          .catch(({ response }) => {
            window.debugConsole('got error' + response);
            context.commit(
                SET_ERROR,
                '[Ref:' +
                response.data.results.ref +
                '] ' +
                i18n.t('store/sub:sso error')
            );
            reject(
                SET_ERROR,
                '[Ref:' +
                response.data.results.ref +
                '] ' +
                i18n.t('store/sub:sso error')
            );
          });
    });
  }
};

const mutations = {
  setUberSub(state, payload){
    state.hasUberSub = payload;
  },
  setLanguageDropdown(state, payload){
    state.languageDropdown = payload;
  },
  [SET_NEW_SUB](state, subDetails) {
    state.newSub = subDetails;
  },

  /* Dashboard Widgets */
  [SET_WIDGETS](state, widgets) {
    state.widgetColumns = widgets;
  },
  [SET_STATGRAPHS](state, widgets) {
    state.statGraphs = widgets;
  },
  [SET_INACTIVE_WIDGETS](state, widgets){
    state.inactiveWidgets = widgets;
  },
  [SET_INACTIVE_GRAPHS](state, widgets){
    state.inactiveGraphs = widgets;
  },
  [SET_PERSONS](state, persons) {
    state.persons = persons;
  },
  [SET_STORE_LOADING_STATE](state, data){
    if(data){
      state.isStoreLoading += 1;
    }else{
      state.isStoreLoading -= 1;
    }
  },
  [SET_WARN](state, data){
    switch(data.option){
      case 'delete':
        state.warn.id = 1;
        state.warn.title = i18n.t('store/view:users:warn delete title');
        state.warn.message = i18n.t('store/view:users:warn delete message');
        state.warn.submit = i18n.t('store/view:users:warn delete submit');
        break;

      case 'suspend':
        state.warn.id = 2;
        state.warn.title = i18n.t('store/view:users:warn suspend title');
        state.warn.message = i18n.t('store/view:users:warn suspend message');
        state.warn.submit = i18n.t('store/view:users:warn suspend submit');
        break;

      case 'reactivate':
        state.warn.id = 3;
        state.warn.title = i18n.t('store/view:users:warn reactivate title');
        state.warn.message = i18n.t('store/view:users:warn reactivate message');
        state.warn.submit = i18n.t('store/view:users:warn reactivate submit');
        break;

      default:
        window.debugConsole('Warn Modal Could Not Be Set: Invalid value passed');
    }
    state.warn.person = data.person;
  },
  [SET_PERSON_DETAIL](state, data){
    state.personDetail = data;
  },
  [SET_PERSON_ONE](state, data){
    state.personOne = data;
  }
  // [DEACTIVATE_WIDGET](state, component){
  //   state.widgetColumns.forEach(column => {
  //     column.widgets.forEach(widget => {
  //       if(widget.component == component){

  //       }
  //     });
  //   });
  // },
  // [ACTIVATE_WIDGET](state, title){
  //   state.widgetColumns.forEach(column => {
  //     column.widgets.forEach(widget => {
  //       if(widget.title == title)
  //         widget.isActive = true;
  //     });
  //   });
  // }
};

export default {
  state,
  actions,
  mutations,
  getters
};
