const disableEnterDirective = {
    bind: function (el) {
      el.disableEnterSubmit = (event) => {
        if (event.keyCode === 13 || event.key === "Enter") {
          event.preventDefault();
        }
      };

      window.addEventListener("keydown", el.disableEnterSubmit);
    },

    unbind: function (el) {
      window.removeEventListener("keydown", el.disableEnterSubmit);
    },
  };

  export default disableEnterDirective;
