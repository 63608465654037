export const CHECK_AUTH = 'checkAuth';
export const GET_INFO = "getInfo";
export const EMAIL_SUBMIT = 'emailSubmit';
export const EMAIL_SUBMIT_FOR_RECOVERY = 'emailSubmitForRecovery';
export const SET_PASSWORD = 'setPassword';
export const REGISTRATION_VERIFACTION = 'registrationVerification';
export const REGISTRATION_SUBMIT = 'registrationSubmit';
export const FETCH_REGISTRATION_REQUIREMENTS = 'fetchRegistrationRequirements';
export const PASSWORD_SUBMIT = 'passwordSubmit';
export const LOGOUT = 'logOut';
export const UPDATE_USER = 'updateUser';
export const PASSWORD_AUTH = 'checkPassword';
export const FETCH_PARTNER = 'getPartner';
export const FETCH_GRID_DATA = 'getGridData';
export const FETCH_PLANS = 'getPlans';
export const SET_OFFER = 'setViewOffer';
export const PURCHASE = 'purchase';
export const STRIPE_CONFIRM_PURCHASE = 'stripeConfirmPurchase';
export const EDIT_SUB = 'editSub';
export const GET_PROFILE_COMPLETENESS = 'getProfileCompleteness';
export const CANCEL_SUB = 'cancelSub';
export const RESTART_SUB = 'restartSub';
export const SSO = 'doSSO';
export const VENDOR_SSO = 'doVendorSSO';
export const CREATE_USER_ID = 'createUserId';
export const CREATE_USER_IF_NEEDED = 'createUser';
export const CREATE_DOMAIN_IF_NEEDED = 'createDomain';
export const FETCH_I18n_DATA = 'fetchI18nData';
export const RESET_APP_STATE = 'resetAppState';
export const PARTNER_SW_PLUGIN = 'partnerSwPlugIn';
export const FETCH_USER = 'getUser';
export const DEEP_LINKED_FOLLOWED = "deepLinkedFollowed";
export const UPDATE_PRODUCT_DEEP_LINK = 'updateProductDeepLink';
export const GET_DOMAIN_INFO = 'getDomainInfo';
export const FETCH_COUNTRIES = 'getCountries';
export const SET_OPINION = 'setOpinion';
export const SUBMIT_FEEDBACK = 'submitFeedback';
export const SET_ONBOARDING = "setOnboarding";
export const SET_GATEWAY_LOADING = "setGatewayLoading";
export const SET_SHOW_SIDE_BAR = "setSideBar";
export const SET_MIGRATION_OFFER = "setMigrationOffer";
export const SET_MIGRATION_STAGE = "setMigrationStage";
export const GET_LEGACY_SSO_URL = "getLegacySsoUrl";
export const GET_LATITUDE_SSO_URL = "getLatitudeSsoUrl";
export const GET_FINISHED_LATITUDE_SSO_URL = "getFinishedLatitudeSsoUrl";
export const SITE_MIGRATION = "siteMigrationSwitch";
export const BEGIN_DIY_MIGRATION = "beginDiyMigration";
export const BEGIN_DIFM_MIGRATION = "beginDifmMigration";
export const FETCH_DIFM_CURRENT_STEP = "fetchDifmCurrentStep";
export const FETCH_PREVIEW_DOMAIN = "fetchPreviewDomain";
export const SUBMIT_PA_JOB_FEEDBACK = 'fetchPaJobFeedback';
export const PA_JOB_NEXT_STEP = "paJobNextStep";
export const LEG2LAT_CHECK_PUBLISH = "leg2latCheckPublish";
export const PA_JOB_DOMAIN = "paJobDomain";
export const FETCH_DIFM_STEPS = "fetchDifmSteps";
export const FETCH_SITEMIG_IMG_URLS = "fetchsitemigImgUrls";
export const SUBMIT_DIFM_REVIEW_FOR_DEV = "submitDifmReviewForDev";
export const GET_MULTI_PRODUCT_DATA = "getMultiProductData";
export const PRODUCT_PURCHASE_SSO = "productPuchaseSso";
export const CHECK_IF_SESSION_EXISTS = "checkIfSessionExists";
export const GET_SKU_SALES_ACTION = "getSkuSalesAction";


// Widget Actions
export const GET_ACTIVITY_FEED = 'getActivityFeed';
export const GET_INIT_UI = 'getInitUI';
export const FETCH_STATGRAPHS = 'fetchStatGraphs';
export const FETCH_WIDGETS = 'fetchWidgets';
export const DEACTIVATE_WIDGET = 'deactivateWidget';
export const ACTIVATE_WIDGET = 'activateWidget';
export const FETCH_INACTIVE_WIDGETS = 'fetchInactiveWidgets';
export const FETCH_INACTIVE_GRAPHS = 'fetchInactiveGraphs';
export const UPDATE_WIDGETS = 'updateWidgets';
export const GET_DATA_POINTS = 'getDataPoints';
export const GET_LISTING = 'getListing';
export const ENSURE_WIDGET_USER = 'ensureWidgetUser';
export const CREATE_WIDGET_USER = 'createWidgetUser';
export const CHECK_UBER_SUB = 'checkUberSub';
export const GET_HOMEPAGE_CARDS = 'getHomePageCards';

// Person Actions
export const CREATE_PERSON = 'createPerson';
export const DELETE_PERSON = 'deletePerson';
export const UPDATE_PERSON = 'updatePerson';
export const SUSPEND_PERSON = 'suspendPerson';
export const GET_PERSON_ONE_GUID = 'getPersonOneGuid';
export const FETCH_LANGUAGE_DROPDOWN = 'fetchLanguageDropdown';

// Dashboard Actions
export const SET_STORE_LOADING = 'setStoreLoading';
export const GET_ENDPOINT = 'getEndpoint';



