import Vue from 'vue';
import i18n from './common/i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import { GET_INFO, FETCH_I18n_DATA } from './store/actions.type'; // CHECK_AUTH
import ApiService from './common/api.service';
import disableEnterDirective from "./helpers/disableEnterDirective";

Vue.directive("disable-enter", disableEnterDirective);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { ModalPlugin, BootstrapVue } from 'bootstrap-vue'

Vue.use(ModalPlugin);
Vue.use(BootstrapVue);

Vue.config.productionTip = false;
ApiService.init();
Vue.use(require('vue-moment'));

// window.debugMode = store.getters.getDebug;
window.debugConsole = function(msg) {
  if(store.getters.getDebug){
    console.log(msg);
  }
};

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}
const loadedLanguages = [];
export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }

  return store.dispatch(FETCH_I18n_DATA).then(data => {
    let msgs = data;
    loadedLanguages.push(lang);
    i18n.setLocaleMessage(lang, msgs);
    setI18nLanguage(lang);
    document.title = i18n.t('store/title') != 'store/title' ? i18n.t('store/title') : i18n.t('gateway/title');
  });
}

export function setFavicon(url, appleLink){
  const faviconLink = document.createElement('link');
  faviconLink.rel = 'icon';
  faviconLink.type = 'image/x-icon';
  faviconLink.href = url;

  const appleFaviconLink = document.createElement('link');
  appleFaviconLink.rel = 'apple-touch-icon';
  appleFaviconLink.href = appleLink;

  const existingFavicon = document.querySelector("link[rel='icon']");
  if (existingFavicon) {
    document.head.removeChild(existingFavicon);
  }

  const existingAppleFavicon = document.querySelector("link[rel='apple-touch-icon']");
  if (existingAppleFavicon) {
    document.head.removeChild(existingAppleFavicon);
  }

  document.head.appendChild(faviconLink);
  document.head.appendChild(appleFaviconLink);
}

// Ensure we load language before each page load.
router.beforeEach((to, from, next) => {
  if(to.params.brand && to.name == 'login') {
      store.dispatch(GET_INFO, to.params.brand).then(data => {
        Vue.$cookies.set('ssi_person_notifications', data.ssi_person_notifications);
        Vue.$cookies.set('partner_id', data.partner_id);
        setFavicon(data.faviconURL, data.appleFaviconUrl);
        loadLanguageAsync(data.language).then(next);
      });
  } else {
    store.dispatch(GET_INFO).then(data => {
      Vue.$cookies.set('partner_id', data.partner_id);
      Vue.$cookies.set('ssi_person_notifications', data.ssi_person_notifications);
      setFavicon(data.faviconURL, data.appleFaviconUrl);
      loadLanguageAsync(data.language).then(next);
    });
  }
});

// function goOut() {
//   let partner_id = Vue.$cookies.get('partner_id');
//   window.debugConsole(' partner_id', partner_id);
//   if (!partner_id) {
//     partner_id = 'telenor_no';
//   }
//   window.location = '../' + partner_id;
// }
document.addEventListener('DOMContentLoaded', function() {
  console.log('home page');
});
// Vue Breadcrumbs source code
function VueBreadcrumbs(Vue) {
  Object.defineProperties(Vue.prototype, {
    $breadcrumbs: {
      get: function() {
        var crumbs = [];
        for (var i = 0; i < this.$route.matched.length; i++) {
          if (
            this.$route.matched[i].handler &&
            this.$route.matched[i].handler.breadcrumb
          ) {
            crumbs.push(this.$route.matched[i]);
          }
        }
        return crumbs;
      }
    }
  });

  Vue.component('breadcrumbs', {
    template:
      '<nav class="breadcrumbs" v-if="$breadcrumbs.length"> <ul> <li v-for="(i, crumb) in $breadcrumbs"> <a v-link="crumb">{{ $t(crumb.handler.breadcrumb) }}</a> </li> </ul> </nav>'
  });
}
Vue.use(VueBreadcrumbs);

Vue.filter('capitalize', function(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
new Vue({
  router,
  store,
  VueBreadcrumbs,
  i18n,
  render: h => h(App)
}).$mount('#app');
