/* eslint-disable no-unused-vars */
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { API_URL } from '@/common/config';
const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setHeader() {
    // Vue.axios.defaults.headers.common[
    //   'Authorization'
    // ] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[SBS] ApiService ${error}`);
    });
  },

  yiiQuery(resource, params = {}) {
    var slugArray = [];

    for (var property in params) {
        if (Object.prototype.hasOwnProperty.call(params, property)) {
          slugArray.push(property + '=' + params[property]);
        }
    }
    var endpoint = encodeURIComponent(resource);
    var query = (slugArray.length>0)?( '&query=' + encodeURIComponent(slugArray.join('&'))):(''); // ('&query='+query):(''
    return ApiService.query('/na/site/api?endpoint=' + endpoint + query);
  },

  yiiPost(resource, params = {}, extra_headers = {}){
    let toSend = {
      headers: {
        ...extra_headers,
        ...config.headers
      }
    };
    return ApiService.post('na/site/api?endpoint='+encodeURI(resource), params, toSend);
  },

  yiiPut(resource, params = {}, extra_headers = {}){
    let toSend = {
      headers: {
        ...extra_headers,
        ...config.headers
      }
    };
    return ApiService.put('na/site/api?endpoint='+encodeURI(resource), params, toSend);
  },

  yiiDelete(resource, params = {}, extra_headers = {}){
    let toSend = {
      headers: {
        ...extra_headers,
        ...config.headers
      }
    };
    return ApiService.delete('na/site/api?endpoint='+encodeURI(resource), params, toSend).catch(error => {
      throw new Error(`[SBS] ApiService ${error}`);
    });
  },

  get(resource, slug = '') {
    return Vue.axios
      .get(slug ? `${resource}/${slug}` : `${resource}`)
      .catch(error => {
        throw new Error(`[SBS] ApiService ${error}`);
      });
  },

  post(resource, params, extra_headers = {}) {
    let toSend = {
      headers: {
        ...extra_headers,
        ...config.headers
      }
    };
    return Vue.axios.post(resource, params, toSend);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params, config);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[SBS] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const SubService = {
  query(params) {
    return ApiService.yiiQuery('users',
      params
    );
  },
  get() {
    return ApiService.yiiQuery('users');
  }
};

export const PlanService = {
  query(params) {
    return ApiService.yiiQuery('plans',
      params
    );
  }
};

export const CountriesService = {
  query(params) {
    return ApiService.yiiQuery('countries',
      params
    );
  }
};


export const I18nService = {
  get(lang) {
    Vue.axios.defaults.headers.common['Accept-Language'] = lang;
    return ApiService.yiiQuery("i18n/"+lang);
  }
};

export const PartnerService = {
  get() {
    return ApiService.get('');
  }
};

export const SwPluginService = {
  get(params) {
    return ApiService.yiiQuery('sw/wpPlugin',
      params
    );
  }
};
