import Vue from 'vue';
import ApiService from '@/common/api.service';
import {
  CountriesService,
  SubService,
  PlanService,
  PartnerService,
  I18nService,
  SwPluginService
} from '@/common/api.service';
// import { PCATS } from '@/common/pcats';
import i18n from '@/common/i18n';
import {
  SUBS_LIVE_STATUS,
  CAMPAIGN_STATUS_UNPAID,
  CAMPAIGN_STATUS_SUSPENDED,
  CAMPAIGN_STATUS_CANCELLED
} from '@/common/constant';
import {
  FETCH_GRID_DATA,
  FETCH_PLANS,
  FETCH_PARTNER,
  SET_OFFER,
  FETCH_I18n_DATA,
  RESET_APP_STATE,
  PARTNER_SW_PLUGIN,
  FETCH_COUNTRIES,
  GET_DATA_POINTS,
  GET_PROFILE_COMPLETENESS,
  GET_LISTING,
  GET_LEGACY_SSO_URL,
  GET_LATITUDE_SSO_URL,
  GET_FINISHED_LATITUDE_SSO_URL,
  SITE_MIGRATION,
  BEGIN_DIY_MIGRATION,
  BEGIN_DIFM_MIGRATION,
  SET_ONBOARDING,
  SET_GATEWAY_LOADING,
  SET_SHOW_SIDE_BAR,
  GET_INIT_UI,
  CHECK_DEEP_LINK,
  UPDATE_PRODUCT_DEEP_LINK,
  DEEP_LINKED_FOLLOWED,
  GET_ACTIVITY_FEED,
  GET_ENDPOINT,
  GET_HOMEPAGE_CARDS,
  SET_MIGRATION_OFFER,
  SET_MIGRATION_STAGE,
  FETCH_DIFM_CURRENT_STEP,
  SUBMIT_PA_JOB_FEEDBACK,
  PA_JOB_NEXT_STEP,
  LEG2LAT_CHECK_PUBLISH,
  PA_JOB_DOMAIN,
  FETCH_PREVIEW_DOMAIN,
  FETCH_DIFM_STEPS,
  FETCH_SITEMIG_IMG_URLS,
  SUBMIT_DIFM_REVIEW_FOR_DEV,
  PRODUCT_PURCHASE_SSO
} from './actions.type';
import {
  SET_PARTNER,
  FETCH_START,
  UPDATE_DATA_IN_GRID,
  SET_NEW_USER_ID,
  SET_PLANS,
  SET_PCATS,
  SET_SUBSCRIBED_CORE_PCATS,
  SET_OFFER_DATA,
  SET_CURRENCY,
  // SET_AUTH,
  SET_ERROR,
  RESET_STATE,
  SET_COUNTRIES,
  SET_ONBOARDING_STATE,
  SET_GATEWAY_LOADING_STATE,
  SET_CURRENT_DIFM_STEP,
  SET_SIDE_BAR_STATE,
  SET_MIGRATION_OFFER_STATE,
  SET_MIGRATION_STAGE_STATE,
  SET_DEEP_LINK,
  SET_DEEP_LINKED_FOLLOWED,
  SET_DIFM_STEPS,
  SET_DIFM_STEP_DETAIL,
  SET_SITEMIG_IMG_URLS,
  SET_JOB_FEEDBACK,
  SET_PA_JOB_DOMAIN,
} from './mutations.type';

const initialState = {
  currency: '',
  partner: '',
  subs: [],
  users: [],
  allowedPcatsToSubscribe: [],
  usersData: [],
  campaigns: [],
  userIds: [],
  domains: [],
  plans: [],
  planIds: [], // plans keyed by planIds
  p3CountryCodes: [],
  countries: [],
  pcats: [], // pcats needed for partner
  coreP3s: [],


  purchaseOnStripeConfirmed: false,
  stripePurchase: null,
  debug: true,
  isLoading: true,
  isOnboarding: true,
  isGatewayLoading: false,
  isMigrationOffer: false,
  migrationStage: 0,
  showSideBar: false,
  deepLinked: false,
  followedDeepLink: false,
  productDeepLink: null,
  registeredUserIds: [],
  subscribedDomains: [],
  subscribedCoreP3s: [], // hold subscribed core Pcats
  allowedPcatsHome: [], // hold pcats for home tiles
  viewOfferData: [],
  opinionFeedback: false,
  homeErrors: '',
  subListType: 'verbose',

  homeLoading: true, // Spinner variables
  listingsLoading: false,
  dataPointsLoading: false,
  activityFeedLoading: false,
  profileCompletenessLoading: false,

  fieldsInSync: 0, // Listing variables
  listingBeingUpdated: 0,
  listingsInSync: 0,
  dirMissingConnect: [],
  locationsNeedsReview: 0,
  locationsRequireSync: 0,
  selectedLocation: "None",

  activityFeed: [], // Activity Feed variable

  visibilityScore: 0, // Visibility variables
  visibilityDataPoints: [],

  profileCompleteness: {}, // Profile completeness variables
  profileLevel: 'Required',

  googleClicks: 0, // Google Click variables
  googleClicksDataPoints: [],

  googleImpressions: 0, // Google impressions variables
  googleImpressionsDataPoints: [],

  facebookClicks: 0, // Facebook Click variables
  facebookClicksDataPoints: [],

  facebookImpressions: 0, // Facebook impressions variables
  facebookImpressionsDataPoints: [],

  homeCustomerFeedback : [], // Uberall home page variables
  homeProfileCompleteness : [],
  homeListingsHealth: [],
  homeInsights: [],
  homeSearchRank: [],
  homeToDos: [],

  startDate:0, // Filter variables
  endDate:0,

  address: '',  // User variables
  location: 0,
  managedLocations: [],

  phpSession: '', // PHP session

  difmCurrentStep: '', //Site Migration Variables
  difmSteps: '',
  difmStepDetail: '',
  imgUrlsForReview: [],

  // paJobFeedback: {'rating': null, 'msg': null},
  paJobFeedback: {},
  paJobDomain: '',

  filterComponentList: [
    'VisibilityIndex',
    'FacebookClicks',
    'FacebookImpressions',
    'GoogleClicks',
    'GoogleImpressions',
  ],

};

export const state = { ...initialState };

const getters = {
  paJobDomain(state){
    return state.paJobDomain;
  },
  difmCurrentStep(state){
    return state.difmCurrentStep;
  },
  difmStepDetail(state){
    return state.difmStepDetail;
  },
  difmSteps(state){
    return state.difmSteps;
  },
  paJobFeedback(state){
    return state.paJobFeedback;
  },
  siteMigImgsForReview(state){
    return state.imgUrlsForReview;
  },
  uberallHomeToDos(state){
    return state.homeToDos;
  },
  uberallHomeCustomerFeedback(state){
    return state.homeCustomerFeedback;
  },
  uberallHomeProfileCompleteness(state){
    return state.homeProfileCompleteness;
  },
  uberallHomeListingsHealth(state){
    return state.homeListingsHealth;
  },
  uberallHomeInsights(state){
    return state.homeInsights;
  },
  uberallHomeSearchRank(state){
    return state.homeSearchRank;
  },
  getHomeLoading(state){
    return state.homeLoading;
  },
  getDataLoading(state){
    return state.dataPointsLoading;
  },
  productDeepLink(state){
    return state.productDeepLink;
  },
  getFilterComponentList(state){
    return state.filterComponentList;
  },
  getEndDate(state){
    return state.endDate;
  },
  getStartDate(state){
    return state.startDate;
  },
  getLocation(state){
    return state.location;
  },
  getManagedLocations(state){
    return state.managedLocations;
  },
  getPHPSession(state){
    return state.phpSession;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isOnboarding(state){
    return state.isOnboarding;
  },
  isMigrationOffer(state){
    return state.isMigrationOffer;
  },
  migrationStage(state){
    return state.migrationStage;
  },
  isSideBarVisiable(state){
    return state.showSideBar;
  },
  partner(state) {
    return state.partner;
  },
  currency(state) {
    return state.currency;
  },
  subs(state) {
    return state.subs;
  },
  users(state) {
    return state.users;
  },
  campaigns(state) {
    return state.campaigns;
  },
  pcats(state) {
    return state.pcats;
  },
  getUserByDomain: state => domain => {
    return state.users.find(user => user.domain == domain);
  },
  getPcatByP3: state => p3 => {
    return state.pcats.find(pcat => pcat.p3 === p3);
  },
  getPcatByType: state => (type, vendor) => {
    return state.pcats.find(pcat => pcat.type == type && pcat.vendor == vendor);
  },
  // eslint-disable-next-line no-unused-vars
  getCampaignByID: state => (campaigns, campID) => {
    if (campaigns && campaigns.length > 0) {
      return campaigns.find(campaign => campaign.campID === campID);
    }
  },
  plans(state) {
    return state.plans;
  },
  getPlanById: state => plan_id => {
    return state.plans.find(plan => plan.planID == plan_id);
  },
  userIds(state) {
    return state.userIds;
  },
  domains(state) {
    return state.domains;
  },
  subscribedCoreP3s(state) {
    return state.subscribedCoreP3s;
  },
  subscribedDomains(state) {
    return state.subscribedDomains;
  },
  allowedPcatsHome(state) {
    return state.allowedPcatsHome;
  },
  coreP3s(state) {
    return state.coreP3s;
  },
  viewOfferData(state) {
    return state.viewOfferData;
  },
  getP3CountryCodes(state){
    return state.p3CountryCodes;
  },
  getCountries(state){
    return state.countries;
  },
  getDebug(state){
    return state.debug;
  },
};

        // context.commit('setHomePageFeedback', data.feedback.response);
        // context.commit('setHomePageProfileCompleteness', data.profileCompleteness.response);
        // context.commit('setHomePageListingHealth', data.listingHealth.response);
        // context.commit('setHomePageInsights', data.insights.response);
        // context.commit('setHomePageSearchRank', data.searchRank.response);
        // context.commit('setHomePageToDos', data.ToDos.response);

const actions = {
  purchaseStatus(){
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/purchase-status')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [LEG2LAT_CHECK_PUBLISH](context){
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/checkifpublished')
        .then(({ data }) => {
          window.debugConsole('site pubished: ' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [PA_JOB_NEXT_STEP](context){
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/pajobnextstep')
        .then(({ data }) => {
          window.debugConsole('pa job next step: ' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data.domain);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [PA_JOB_DOMAIN](context){
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/pa-job-domain')
        .then(({ data }) => {
          context.commit(SET_PA_JOB_DOMAIN, data.domain);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [FETCH_PREVIEW_DOMAIN](context){
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/fetchpreviewdomain')
        .then(({ data }) => {
          window.debugConsole('fetchpreviewdomain: ' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [SUBMIT_PA_JOB_FEEDBACK](context, submitData){
    return new Promise((resolve, reject) => {
      ApiService.post('na/migration/submitpajobfeedback', submitData)
        .then(({ data }) => {
          window.debugConsole('PA Job feedback: ' + data);
          context.commit(SET_JOB_FEEDBACK, data.feedback);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [FETCH_DIFM_CURRENT_STEP](context){
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/fetchdifmcurrentstep')
        .then(({ data }) => {
          window.debugConsole('PA Current Step Fetched' + data);
          if(data.detail.ssi_route != null && data.detail.ssi_route){
            context.commit(SET_CURRENT_DIFM_STEP, data.stepIdForUI);
            context.commit(SET_DIFM_STEP_DETAIL, data.detail);
            context.commit(SET_JOB_FEEDBACK, data.feedback);
          }
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [FETCH_DIFM_STEPS](context){
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/fetchdifmsteps')
        .then(({ data }) => {
          window.debugConsole('DIFM PA Steps Fetched' + data);
          let filteredSteps = [];
          data.detail.forEach((step, i) => {
            if(step.ssi_route != null && step.ssi_route){
              filteredSteps[i] = data.namesForUI[i];
            }
          });
          context.commit(SET_DIFM_STEPS, filteredSteps);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [FETCH_SITEMIG_IMG_URLS](context){
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/fetchdifmimgurls')
        .then(({ data }) => {
          context.commit(SET_SITEMIG_IMG_URLS, data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [SUBMIT_DIFM_REVIEW_FOR_DEV](context, data){
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.post('na/migration/submitdifmreviewmessagetodev', data)
        .then(({ data }) => {
          //set the stuffs
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [GET_HOMEPAGE_CARDS](context){
    return new Promise((resolve, reject) => {
      ApiService.post('na/site/getuberallhomepagecards')
      .then(({ data }) => {
        ApiService.post('na/site/getuberallhomepagedata')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log('failed ' + response);
          context.commit(SET_ERROR, "Get Uberall Dashboard Card Data Failed.");
          reject();
        })
        resolve(data);
      })
      .catch(({ response }) => {
        console.log('failed ' + response);
        context.commit(SET_ERROR, "Get Uberall Dashboard Cards Failed.");
        reject();
      })
    });
  },
  [GET_ENDPOINT]({commit, getters}, payload){
    var filterParams = {};
    filterParams['endpoint'] = payload;
    if(typeof filterParams.locationId !== 'string'){
      filterParams['locationId']= Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)];
    }

    var header = {
      dashID: getters.getPHPSession
    };
    return new Promise((resolve, reject) => {
    ApiService.post('na/site/reqendpointurl', filterParams, header)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        console.log('failed ' + response);
        console.log("Rejecting APIService");
        commit(SET_ERROR, "The url couln't be recieved.\nGet EndPoint failed.");
        reject();
      })
    });
  },
  [GET_ACTIVITY_FEED]({commit, getters}){ // payload
    var filterParams = {};
    if(typeof Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)] === 'string'){
      filterParams['locationId'] = Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)];
    }
    var header = {
      dashID: getters.getPHPSession
    };
    return new Promise((resolve, reject) => {
      ApiService.post('na/site/getactivityfeed', filterParams, header)
        .then(({ data }) => {
          commit('setActivityFeed',data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log('failed ' + response);
          commit('setHomeError', "Activity Feed could not be loaded.\n Get Activity Feed failed.");
          reject();
        })
      });
  },
  [GET_INIT_UI]({ commit, getters }) {
    window.debugConsole('init UI...');
    // commit("setAddress", "");

    var filterParams = {};
    if(typeof filterParams.locationId !== 'string'){
      filterParams['locationId']= Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)];
    }

    var header = {
      dashID: getters.getPHPSession
    };
    return new Promise((resolve, reject) => {
      window.debugConsole("Starting APIService");
      ApiService.post('na/site/reqinitui', filterParams, header)
        .then(({ data }) => {
          var unpacked = data.userInfo;
          // commit(SET_AUTH, {user: Object.values(unpacked.location)[0]}); //, token: unpacked.token
          commit("setLocationData", [Object.values(unpacked.location)[0], unpacked.managedLocations]);
          commit("setAddress", unpacked.address);

          window.debugConsole("Resolving APIService");
          resolve(unpacked);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          window.debugConsole("Rejecting APIService");
          commit(SET_ERROR, "Could not be authenticated.\n Get Info failed.");
          reject();
        });
    });
  },
  [GET_LISTING]({ commit, getters }){
    var filterParams = {};
    if(typeof Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)] === 'string'){
      filterParams['locationId'] = Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)];
    }

    var header = {
      dashID: getters.getPHPSession,
      'X-CSRF-Token': getters.currentUser.token
    };
    return new Promise((resolve, reject) => {
      ApiService.post('na/site/getlisting', filterParams, header)
        .then(({ data }) => {
          var unpack = JSON.parse(data.listing);
          commit('spinnerOff', 'Listings');
          commit('setListingInfo',unpack);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          window.debugConsole("Rejecting APIService");
          commit('spinnerOff', 'Listings');
          commit('setHomeError', "User listings could not be loaded.\n Get Listing failed.");
          // commit(SET_ERROR, "User listings could not be loaded.\n Get Listing failed.");
          reject();
        })
      });

  },
  [GET_PROFILE_COMPLETENESS]({commit, getters}){
    var filterParams = {};
    if(typeof Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)] === 'string'){
      filterParams['locationId'] = Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)];
    }
    var header = {
      dashID: getters.getPHPSession,
      'X-CSRF-Token': getters.currentUser.token
    };
    commit('spinnerOn', 'ProfileCompleteness');
    return new Promise((resolve, reject) => {
      ApiService.post('na/site/getprofilecompleteness', filterParams, header)
        .then(({ data }) => {
          window.debugConsole(data['profile_completeness']);
          commit('setProfileCompleteness',data['profile_completeness']);
          commit('spinnerOff', 'ProfileCompleteness');
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          window.debugConsole("Rejecting APIService");
          commit('spinnerOff', 'ProfileCompleteness');
          commit('setHomeError', "Profile completeness could not be loaded.\n Get Profile Completeness failed.");
          reject();
        })
      });
  },
  [GET_DATA_POINTS]({ commit, getters }){ // context
    var filterParams = {
      start: getters.getStartDate,
      end: getters.getEndDate,
      componentList: getters.getFilterComponentList,
    };

    if(typeof Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)] === 'string'){
      filterParams['locationId'] = Object.keys(getters.getManagedLocations)[Object.values(getters.getManagedLocations).indexOf(getters.getLocation)];
    }

    var componentInfo = [
      {component:'VisibilityIndex', setMutation: 'setVisibilityData'},
      {component:'FacebookClicks', setMutation: 'setFacebookClicksData'},
      {component:'FacebookImpressions', setMutation: 'setFacebookImpressionsData'},
      {component:'GoogleClicks', setMutation: 'setGoogleClicksData'},
      {component:'GoogleImpressions', setMutation: 'setGoogleImpressionsData'},
      {component:'CustomerFeedback', setMutation: 'setCustomerFeedbackData'},
      {component:'CustomerFeedbackKeywords', setMutation: 'setCustomerFeedbackKeywordsData'},
    ];

    componentInfo.forEach(element => { // Clear old data, to prepare for the new
        commit(element.setMutation,[]);
    });

    var header = {
      dashID: getters.getPHPSession,
      'X-CSRF-Token': getters.currentUser.token
    };
    return new Promise((resolve, reject) => {
      ApiService.post('na/site/getdatapoints', filterParams, header)
        .then(({data}) => { //
          var componentData = data.dataPoints;
          commit('spinnerOff', 'DataPoints');
          componentInfo.forEach(element => {
            if(element.component in componentData && componentData[element.component].length>0){
              // window.debugConsole(element, "  ", componentData[element.component]);
              commit(element.setMutation,componentData[element.component]);
            }
          });

          resolve();
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          window.debugConsole("Rejecting APIService");
          commit('spinnerOff', 'DataPoints');
          commit('setHomeError', "The users Data Points weren't recieved/couln't be parsed.\nGet Data Points failed.");
          // commit(SET_ERROR, "The users Data Points weren't recieved/couln't be parsed.\nGet Data Points failed.");
          reject();
        })
      });
  },
  [FETCH_GRID_DATA](context, params) {
    context.commit(FETCH_START);
    params.filters['lang'] = context.rootState.auth.user.language;
    if (!context.rootState.auth.user.accountId) {
      context.commit(SET_ERROR, i18n.t('store/auth accountId_missing'));
      return;
    }
    return SubService.query(params.filters)
      .then(async ({ data }) => {
        if (data.results && data.results.code == '200') {
          await context.commit(UPDATE_DATA_IN_GRID, data.results);
          await context.commit(CHECK_DEEP_LINK);
          context.commit(SET_SUBSCRIBED_CORE_PCATS);
        } else {
          window.debugConsole('Got error', data.results.status);
          context.commit(
            SET_ERROR,
            data.results && data.results.status
              ? '[Ref:' + data.results.ref + '] ' + data.results.status
              : i18n.t('store/view:home:grid load_error')
          );
        }
      })
      .catch(error => {
        context.commit(
          SET_ERROR,
          i18n.t('store/view:home:grid load_error_response', { error: error })
        );
      });
  },
  [DEEP_LINKED_FOLLOWED](context){
    context.commit(SET_DEEP_LINKED_FOLLOWED, true);
  },
  async [FETCH_PARTNER]({ commit }) {
    const { data } = await PartnerService.get();
    if (data.results && data.results.code == '200') {
      commit(SET_PARTNER, data.results);
    } else {
      window.debugConsole('Got error', data.results.status);
      commit(
        SET_ERROR,
        data.results && data.results.status
          ? data.results.status
          : 'Error loading Partner!'
      );
    }
  },
  [FETCH_I18n_DATA](context) {
    return new Promise((resolve, reject) => {
      I18nService.get(context.rootState.auth.user.language)
        .then(({ data }) => {
          window.debugConsole('Got response', data);
          if (data.results && data.results.code == '200') {
            window.debugConsole('i18n data', data.results.i18n);
            resolve(data.results.i18n);
          } else {
            window.debugConsole('Got error loading i18n', data.results.status);
            context.commit(
              SET_ERROR,
              data.results && data.results.status
                ? data.results.status
                : 'Error loading I18n Data!'
            );
          }
        })
        .catch(({ response }) => {
          window.debugConsole('failed loading i18n' + response);
          context.commit(SET_ERROR, 'failed loading i18n');
          reject('failed loading i18n');
        });
    });
  },
  [FETCH_COUNTRIES](context){ // CountriesService
    return CountriesService.query({
      lang: context.rootState.auth.user.language
    })
      .then(({ data }) => {
        if (data.results && data.results.code == '200') {
          context.commit(SET_COUNTRIES, data.results);
        } else {
          window.debugConsole('Got error', data.results.status);
          context.commit(
            SET_ERROR,
            data.results && data.results.status
              ? data.results.status
              : 'Error loading plans!'
          );
        }
      })
      .catch(error => {
        context.commit(SET_ERROR, error);
      });
  },
 [FETCH_PLANS](context) {
    return PlanService.query({
      extras: 'prices,meta',
      lang: context.rootState.auth.user.language
    })
      .then(async ({ data }) => {
        if (data.results && data.results.code == '200') {
          await context.commit(SET_PLANS, data.results);
          await context.commit(SET_PCATS);
        } else {
          window.debugConsole('Got error', data.results.status);
          context.commit(
            SET_ERROR,
            data.results && data.results.status
              ? data.results.status
              : 'Error loading plans!'
          );
        }
      })
      .catch(error => {
        context.commit(SET_ERROR, error);
      });
  },
  [GET_LEGACY_SSO_URL](context) {
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/sso?sso_type=legacy')
        .then(({ data }) => {
          window.debugConsole('success after getting legacy sso url' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [GET_LATITUDE_SSO_URL](context) {
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/sso?sso_type=latitude')
        .then(({ data }) => {
          window.debugConsole('success after getting latitude sso url' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [GET_FINISHED_LATITUDE_SSO_URL](context) {
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/sso?sso_type=final-latitude')
        .then(({ data }) => {
          window.debugConsole('success after getting latitude sso url' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [SITE_MIGRATION](context) { //context
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/migrate')
        .then(({ data }) => {
          window.debugConsole('success after migrating leg to lat diy' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [BEGIN_DIY_MIGRATION](context) { //context
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/begindiymigration')
        .then(({ data }) => {
          window.debugConsole('success after migrating leg to lat diy' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [BEGIN_DIFM_MIGRATION](context) { //context
    window.debugConsole("Starting APIService");
    context.commit(SET_GATEWAY_LOADING_STATE, true);
    return new Promise((resolve, reject) => {
      ApiService.query('na/migration/begindifmmigration')
        .then(({ data }) => {
          window.debugConsole('success after migrating leg to lat difm' + data);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          resolve(data);
        })
        .catch(({ response }) => {
          window.debugConsole('failed ' + response);
          context.commit(SET_ERROR, [i18n.t('store/home failed')]);
          context.commit(SET_GATEWAY_LOADING_STATE, false);
          reject(i18n.t('store/home failed'));
        });
    });
  },
  [SET_OFFER](context, params) {
    context.commit(SET_OFFER_DATA, params);
  },
  [SET_ONBOARDING](context, state) {
    context.commit(SET_ONBOARDING_STATE, state);
  },
  [SET_GATEWAY_LOADING](context, state) {
    context.commit(SET_GATEWAY_LOADING_STATE, state);
  },
  [SET_SHOW_SIDE_BAR](context, state) {
    context.commit(SET_SIDE_BAR_STATE, state);
  },
  [SET_MIGRATION_OFFER](context, state) {
    context.commit(SET_MIGRATION_OFFER_STATE, state);
  },
  [SET_MIGRATION_STAGE](context, state) {
    context.commit(SET_MIGRATION_STAGE_STATE, state);
  },
  [UPDATE_PRODUCT_DEEP_LINK](context, deepLink) {
    context.commit(SET_DEEP_LINK, deepLink);
  },
  [RESET_APP_STATE]({ commit }) {
    commit(RESET_STATE);
  },
  [PARTNER_SW_PLUGIN](context) {
    return new Promise(resolve => {
      SwPluginService.get()
        .then(({ data }) => {
          window.debugConsole('got success' + data);
          if (data.results && data.http_code == 200) {
            resolve(data.results);
          }
        })
        .catch(({ response }) => {
          window.debugConsole('got error' + response);
          context.commit(
            SET_ERROR,
            '[Ref:' +
              response.data.ref +
              '] ' +
              i18n.t('store/error:stats:wordpress_plugin_download')
          );
        });
    });
  },
  [PRODUCT_PURCHASE_SSO](context, end_user_id){
    return new Promise((resolve, reject) => {
      ApiService.query('na/site/needs-sso?userid=' + end_user_id, {
        'X-CSRF-Token': context.rootState.auth.user.token
      }).then(({ data }) => {
        resolve(data);
      }).catch(({ response }) => {
        context.commit(
            SET_ERROR, response.data
        );
        reject(response);
      });
    });
  },
  spinnerOn:({commit},payload)=>commit('spinnerOn', payload),
  spinnerOff:({commit},payload)=>commit('spinnerOff', payload),
  setDateRange:({commit}, payload)=>commit('setDateRange', payload),
  setLocation:({commit}, payload)=>commit('setLocation', payload),
  // setPHPSession:({commit}, payload)=>commit('setPHPSession', payload),
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_CURRENT_DIFM_STEP](state, payload){
    state.difmCurrentStep = payload;
  },
  [PA_JOB_DOMAIN](state, data){
    state.paJobDomain = data;
  },
  [SET_DIFM_STEPS](state, payload){
    state.difmSteps = payload;
  },
  [SET_DIFM_STEP_DETAIL](state, payload){
    state.difmStepDetail = payload;
  },
  [SET_JOB_FEEDBACK](state, payload){
    state.paJobFeedback = payload;
  },
  [SET_SITEMIG_IMG_URLS](state, payload){
    state.imgUrlsForReview = payload;
  },
  setStripePurchase(state, payload){
    state.stripePurchase = payload;
  },
  setHomePageToDos(state, payload){
    state.homeToDos = payload;
  },
  setHomePageFeedback(state, payload){
    state.homeCustomerFeedback = payload;
  },
  setCustomerFeedbackKeywordsData(state, payload){
    console.log('Customer Feedback: ', payload);
  },
  setCustomerFeedbackData(state, payload){
    console.log('Customer Feedback: ', payload);
  },
  setActivityFeed(state, payload){
    var activityFeed = payload.activity_feed;
    var hrefs = payload.hrefs;

    activityFeed.forEach(element => {
      var locationId = element.feedHTML.substring(
        element.feedHTML.lastIndexOf("{{") + 2,
        element.feedHTML.lastIndexOf("}}")
      );
      console.log(locationId);
      element.feedHTML = element.feedHTML.replace("{{"+locationId+"}}", hrefs[locationId][1]);

    });

    state.activityFeed = activityFeed;
  },
  setLocationData(state, payload){
    state.location = payload[0];
    state.managedLocations = payload[1];
  },
  setLocation(state, payload){
    state.location = payload;
  },
  setDateRange(state, payload){
    state.startDate = payload[0];
    state.endDate = payload[1];
  },
  setProfileCompleteness(state, payload){
    state.profileCompleteness = payload['completenessPerField'];
    state.profileLevel = payload['currentClassification'];
    window.debugConsole(state.profileCompleteness);
    window.debugConsole(state.profileLevel);
  },
  setGoogleImpressionsData(state, payload){
    var toReturn = [
      ['Date']
    ];
    var nameMap = {
      VIEWS_MAPS : "Website",
      VIEWS_SEARCH : "Phone"
    };
    var clickScore = 0;

    if(payload.length==0){
      state.googleImpressions = 0;
      state.googleImpressionsDataPoints = [];
      return;
    }

    payload[0].data.forEach(element => {
      var date = new Date(new Date(element.period).toUTCString().substr(0, 25)); // Loads date by +1 day
      date.setDate(date.getDate() + 1);
      toReturn.push([date.toJSON().slice(6,10).replace(/-/g,'/')]);
    });

    var i = 1;
    payload.forEach(element => {
      if(typeof nameMap[element.name] === "string"){
        toReturn[0] = toReturn[0].concat([nameMap[element.name]]);
        element.data.forEach(element2 => {
          toReturn[i] = toReturn[i].concat([element2.count]);
          clickScore = clickScore+element2.count;
          i++;
        });
        i=1;
      }
    });

    state.googleImpressions = clickScore;
    state.googleImpressionsDataPoints = toReturn;
  },
  setGoogleClicksData(state, payload){
    var toReturn = [
      ['Date']
    ];
    var nameMap = {
      ACTIONS_WEBSITE : "Website",
      ACTIONS_PHONE : "Phone",
      ACTIONS_DRIVING_DIRECTIONS : "Driving Directions",
    };
    var clickScore = 0;
    if(payload.length==0){
      state.googleClicks = 0;
      state.googleClicksDataPoints = [];
      return;
    }

    payload[0].data.forEach(element => { // Sets the dates
      var date = new Date(new Date(element.period).toUTCString().substr(0, 25)); // Loads date by +1 day
      date.setDate(date.getDate() + 1);
      toReturn.push([date.toJSON().slice(6,10).replace(/-/g,'/')]);
    });

    var i = 1;
    payload.forEach(element => {
      if(typeof nameMap[element.name] === "string"){
        toReturn[0] = toReturn[0].concat([nameMap[element.name]]); // Append name to top row

        element.data.forEach(element2 => { // Append data points per date
          toReturn[i] = toReturn[i].concat([element2.count]);
          clickScore = clickScore+element2.count; // Total sum of call clicks
          i++;
        });
        i=1;
      }
    });

    state.googleClicks = clickScore;
    state.googleClicksDataPoints = toReturn;
  },
  setFacebookImpressionsData(state, payload){
    var toReturn = [
      ['Date', 'Clicks']
    ];
    var clickScore = 0;

    if(payload.length==0){
      state.facebookImpressions = 0;
      state.facebookImpressionsDataPoints = [];
      return;
    }

    payload.forEach(element => {
      var date = new Date(new Date(element.period).toUTCString().substr(0, 25)); // Loads date by +1 day
      // date.setDate(date.getDate() + 1);
      toReturn.push([date.toJSON().slice(6,10).replace(/-/g,'/'), element.count]);
      clickScore = clickScore+element.count;
    });
    // window.debugConsole("Facebook Impressions: ", clickScore);
    state.facebookImpressions = clickScore;
    state.facebookImpressionsDataPoints = toReturn;
  },
  setFacebookClicksData(state, payload){
    var toReturn = [
      ['Date', 'Clicks']
    ];
    var clickScore = 0;

    if(payload.length==0){
      state.facebookClicks = 0;
      state.facebookClicksDataPoints = [];
      return;
    }

    payload.forEach(element => {
      var date = new Date(new Date(element.period).toUTCString().substr(0, 25)); // Loads date by +1 day
      // date.setDate(date.getDate() + 1);
      toReturn.push([date.toJSON().slice(6,10).replace(/-/g,'/'), element.count]);
      clickScore = clickScore+element.count;
    });
    // window.debugConsole("Facebook CLicks: ", clickScore);
    state.facebookClicks = clickScore;
    state.facebookClicksDataPoints = toReturn;
  },
  setVisibilityScore(state, payload){
    state.visibilityScore = payload;
  },
  setVisibilityData(state, payload){
    var dataPoints = [['Date', 'Index']]; // , {'type': 'string', 'role': 'style'}
    var startDate = state.startDate, endDate = state.endDate;
    var start = new Date(startDate.replace(/-/g,'/'));
    var end = new Date(endDate.replace(/-/g,'/'));

    if(payload.length==0 || payload[payload.length-1]['pointsReached']==0){ // No data points returned or zero score on the last entry
      state.visibilityDataPoints = [];
      state.visibilityScore = 0;
      return;
    }
    else if(payload.length==1){ // What if we only have one point?
      dataPoints.push([start.toJSON().slice(6,10).replace(/-/g,'/'),payload[payload.length-1]['pointsReached']]);
      dataPoints.push([end.toJSON().slice(6,10).replace(/-/g,'/'),payload[payload.length-1]['pointsReached']]);
    }
    else{ // We have N data points
      payload.forEach(element => {
        var dateChunks = element['date'].split("-");
        var dateBefore = new Date(dateChunks[0], parseInt( dateChunks[1])-1, parseInt(dateChunks[2])-1).toJSON();
        dataPoints.push([dateBefore.slice(6,10).replace(/-/g,'/'),element['pointsReached']]);
      });
    }

    state.visibilityDataPoints = dataPoints;
    state.visibilityScore = payload[payload.length-1]['pointsReached']; // Score is equal to the last tuples pointsReached, if none set to zero
    return;
    // End of function, the below code was the orginal implementation in which we filled the gaps to the list of points

    // if(payload.length==0){
    //   state.visibilityDataPoints = [];
    //   state.visibilityScore = 0;
    //   return;
    // }
    // else if(payload[payload.length-1]['pointsReached']!=0){ // If score for the last result is zero, dont display graph.
    //   var startDate = state.startDate, endDate = state.endDate;
    //   var startChunks = startDate.split("-");
    //   var from = new Date(startChunks[0], parseInt( startChunks[1])-1, startChunks[2]);
    //   var pos = -1;
    //   for(var i=0;i<payload.length;i++){ // Finds starting index if any
    //     var checkChunks = payload[i]['date'].slice(0,10).split("-");
    //     var check = new Date(checkChunks[0], parseInt( checkChunks[1])-1, checkChunks[2]);
    //     if(check.getTime() === from.getTime() || check > from){ // If we land in the range, set start pos
    //       pos = i;
    //       break;
    //     }
    //   }

    //   var dataPoints = [['Date', 'Index', {'type': 'string', 'role': 'style'}]];
    //   var start = new Date(startDate.replace(/-/g,'/'));
    //   var end = new Date(endDate.replace(/-/g,'/'));
    //   var length = (end.getTime() - start.getTime())/(1000*60*60*24); // How many days do we need to span
    //   var dateCheck = start;
    //   var lastSize = 0;

    //   if(pos!=-1){
    //     for(var j=0;j<=length;j++){ // process the day by day score
    //       if(payload.length>pos && payload[pos]['date'].slice(0,10)=== dateCheck.toJSON().slice(0,10)){
    //         lastSize = payload[pos]['pointsReached'];
    //         dataPoints.push([payload[pos]['date'].slice(6,10).replace(/-/g,'/'),payload[pos]['pointsReached'],'point { visible: true; }']);
    //         pos++;
    //       }
    //       else{
    //         dataPoints.push([ dateCheck.toJSON().slice(6,10).replace(/-/g,'/'), lastSize, (j==0 || j==length)?'point { visible: true; }':'point { visible: false; }']);
    //       }
    //       dateCheck = new Date(dateCheck.getTime() + 24 * 60 * 60 * 1000);
    //     }
    //   }
    //   else{
    //     dataPoints.push([start.toJSON().slice(6,10).replace(/-/g,'/'),payload[payload.length-1]['pointsReached'],'point { visible: true; }']);
    //     dataPoints.push([end.toJSON().slice(6,10).replace(/-/g,'/'),payload[payload.length-1]['pointsReached'],'point { visible: true; }']);
    //   }
    //   state.visibilityDataPoints = dataPoints;
    //   state.visibilityScore =  payload.length>0?payload[payload.length-1]['pointsReached']:0; // Score is equal to the last tuples pointsReached, if none set to zero
    // }
    // else{
    //   state.visibilityDataPoints = [];
    //   state.visibilityScore = 0;
    // }
  },
  setListingInfo(state, payload){
    [
      {stateName:'fieldsInSync',payloadName:'countFieldsInSync'},
      {stateName:'listingBeingUpdated',payloadName:'countListingsBeingUpdated'},
      {stateName:'listingsInSync',payloadName:'countListingsInSync'},
      {stateName:'dirMissingConnect',payloadName:'directoriesMissingConnect'},
      {stateName:'locationsNeedsReview',payloadName:'countLocationsNeedsReview'},
      {stateName:'locationsRequireSync',payloadName:'countLocationsRequireSync'},
    ].forEach(element => {
      if(element.payloadName in payload){
        state[element.stateName] = payload[element.payloadName];
      }
    });
  },
  spinnerOn(state, payload){
    switch(payload){
      case "Home":
        state.homeLoading = true;
      break;
      case "Listings":
        state.listingsLoading = true;
      break;
      case "DataPoints":
        state.dataPointsLoading = true;
      break;
      case "ActivityFeed":
        state.activityFeedLoading = true;
      break;
      case "ProfileCompleteness":
        state.profileCompletenessLoading = true;
      break;
      case "SilentRefresh":
        state.silentlyRefreshing = true;
      break;
    }
  },
  spinnerOff(state, payload){
    switch(payload){
      case "Home":
        state.homeLoading = false;
      break;
      case "Listings":
        state.listingsLoading = false;
      break;
      case "DataPoints":
        state.dataPointsLoading = false;
      break;
      case "ActivityFeed":
        state.activityFeedLoading = false;
      break;
      case "ProfileCompleteness":
        state.profileCompletenessLoading = false;
      break;
      case "SilentRefresh":
        if(state.silentComponentsAccountedFor == 2){
          state.silentlyRefreshing = false;
          state.silentComponentsAccountedFor = 0;
        }
        else{
          state.silentComponentsAccountedFor = state.silentComponentsAccountedFor+1;
        }
      break;
    }
  },
  [FETCH_START](state) {
    state.isLoading = true;
  },
  [SET_COUNTRIES](state, data){

    if(data.detail.countries)
      state.countries = data.detail.countries;
    // window.debugConsole('SETTING COUNTRIES', state.countries);

  },
  [CHECK_DEEP_LINK](state) {
    if(state.productDeepLink && state.pcats.length>0){
      var prodCat = state.pcats.filter(pcat => pcat.p3 == state.productDeepLink.product)[0];
      if((typeof prodCat !== 'undefined')){
        var hasSub = state.subs.filter(sub => (typeof sub.pcat !== 'undefined') && (sub.pcat.p3 == state.productDeepLink))[0];
          if(!hasSub){
            state.deepLinked = true;
          }
      }
    }
    return;
  },
  [SET_DEEP_LINKED_FOLLOWED](state, data){
    state.followedDeepLink = data;
  },
  [UPDATE_DATA_IN_GRID](state, data) {
    let usersData = data.detail.users;
    state.subs = [];
    state.users = [];
    state.campaigns = [];
    state.domains = [];
    state.userIds = [];
    state.subscribedDomains = [];
    state.registeredUserIds = [];
    usersData.map(user => {
      let subExist = false;
      let subCount = 0;
      if (!state.userIds.includes(user.userID)) {
        state.userIds.push(user.userID);
      }
      if (!state.domains.includes(user.domain)) {
        state.domains.push(user.domain);
      }
      if (user.subs && user.subs.length > 0) {
        var registeredProdCats = [];
        var regPLines = [];
        if (!state.subscribedDomains.includes(user.domain)) {
          state.subscribedDomains.push(user.domain);
        }
        user.subs.map(sub => {
          if (SUBS_LIVE_STATUS.indexOf(sub.status) != -1) {
            subCount++;
            subExist = true;
            sub.domain = user.domain;
            sub.domainName = user.domain
              ? subCount == 1
                ? user.domain
                : ''
              : i18n.t('store/view:home:grid:domains location_services');
            sub.userid = user.userID;
            let subPlan = state.planIds[sub.planID];
            sub.pcat = sub.productID3
              ? this.getters['getPcatByP3'](sub.productID3.substr(0, 5))
              : '';
            sub.planName = subPlan ? subPlan.planName : '';
            sub.planDetails = subPlan ? subPlan.description : '';
            sub.planPrice = subPlan ? subPlan.price : 0;
            sub.allowedActions = sub.allowedActions
              ? sub.allowedActions.concat(user.allowedActions)
              : user.allowedActions;
            if (sub.campaignID) {
              var campDetails = this.getters['getCampaignByID'](
                user.campaigns,
                sub.campaignID
              );
              sub.campID =
                campDetails && campDetails.campID ? campDetails.campID : '';
              sub.campName =
                campDetails && campDetails.campName ? campDetails.campName : '';
              sub.planName =
                campDetails && campDetails.campID
                  ? sub.planName + ' - ' + campDetails.campName
                  : sub.planName;
            }
            state.subs.push(sub);

            let regP3 = sub.productID3.substr(0, 2);
            if (!regPLines.includes(regP3)) {
              regPLines.push(regP3);
            }
            if (sub.pcat && !registeredProdCats.includes(sub.pcat.p3)) {
              registeredProdCats.push(sub.pcat.p3);
            }
          }
        });
        state.registeredUserIds[user.userID] = [];
        state.registeredUserIds[user.userID]['prodcats'] = registeredProdCats;
        state.registeredUserIds[user.userID]['prodlines'] = regPLines;
      }
      if (user.campaigns && user.campaigns.length > 0) {
        user.campaigns.map(campaign => {
          if (
            !campaign.subID &&
            (campaign.status == CAMPAIGN_STATUS_UNPAID ||
              campaign.status == CAMPAIGN_STATUS_SUSPENDED ||
              campaign.status == CAMPAIGN_STATUS_CANCELLED)
          ) {
            campaign.domain = user.domain;
            campaign.ID = '';
            campaign.domainName = '';
            campaign.userid = user.userID;
            campaign.planName = i18n.t(
              'store/view:home:grid:services no_service',
              {
                campaignName: campaign.campName
              }
            );
            campaign.planDetails = campaign.planName;
            campaign.pcat = campaign.type
              ? this.getters['getPcatByType'](campaign.type, campaign.vendor)
              : '';
            state.subs.push(campaign);
            state.campaigns.push(campaign);
          }
        });
      }
      if (!subExist && user.userID) {
        var noSub = {
          domain: user.domain,
          domainName: user.domain
            ? user.domain
            : i18n.t('store/view:home:grid:domains no_domain'),
          userid: user.userID
        };
        state.subs.push(noSub);
      }
      state.users.push(user);
    });
    usersData = [];
    window.debugConsole('final subs are :', state.subs);
    // update app state now
    state.isLoading = false;
  },
  [SET_PARTNER](state, data) {
    state.partner = '';
    state.partner = data.detail;
  },
  [SET_PLANS](state, data) {
    state.plans = [];
    state.planIds = [];
    state.coreP3s = [];
    state.p3CountryCodes = [];
    let plans = data.detail.planIDs;
    plans.map(plan => {
      if (plan.showInStore == 1) {
        plan.p3 = plan.productID3.substr(0, 5);
        if (plan.isCore == 1 && state.coreP3s.indexOf(plan.p3) == -1) {
          state.coreP3s.push(plan.p3);
        }
        var prices = plan.prices;
        var priceMatrix = plan.priceMatrix;

        var currencies = Object.keys(prices);
        var constants = {
          '1' : 'month',
          '3' : 'quarter',
          '12' : 'year',
        };
        var defaultSingularTermCode = 'month';
        var defaultMultipleTermCode = 'months';
        var defaultSingularTermText = i18n.t('misc/' + defaultSingularTermCode);
        var defaultMultipleTermText = i18n.t('misc/' + defaultMultipleTermCode);
        var currencyTermOneText, currencyTermTwoText = null;
        currencies.map(currency => {
          if (currency == state.currency) {
            var currencyTermOne, currencyTermTwo = [];
            if(priceMatrix[currency].length >= 2
                && !(priceMatrix[currency][0]['msrp'] == 0 && priceMatrix[currency]['msrp'] == 0)){
              currencyTermOne = priceMatrix[currency][0];
              currencyTermTwo = priceMatrix[currency][1];

              currencyTermOneText = typeof constants[currencyTermOne.term_months] != 'undefined'
                ? i18n.t('misc/' + constants[currencyTermOne.term_months]) : currencyTermOne.term_months + ' ' +  defaultMultipleTermText;
              currencyTermTwoText = typeof constants[currencyTermTwo.term_months] != 'undefined'
                ? i18n.t('misc/' + constants[currencyTermTwo.term_months]) : currencyTermTwo.term_months + ' ' +  defaultMultipleTermText;

              if(currencyTermOne['msrp'] == 0 && currencyTermTwo['msrp'] != 0){
                plan.prettyPrice = i18n.t('store/price_display duel_term_price_first_free_format', {
                  msrp: currencyTermTwo.msrp,
                  term_text_one: currencyTermOneText,
                  term_text_two: currencyTermTwoText,
                  currency: currency,
                });
              }
              else if(currencyTermOne['msrp'] != 0 && currencyTermTwo['msrp'] != 0){
                plan.prettyPrice = i18n.t('store/price_display duel_term_price_format', {
                  msrp_term_one: currencyTermOne.msrp,
                  msrp_term_two: currencyTermTwo.msrp,
                  term_text_one: currencyTermOneText,
                  term_text_two: currencyTermTwoText,
                  currency: currency
                });
              }
            }
            else if(priceMatrix[currency].length == 1
              && priceMatrix[currency][0].msrp != 0){
              currencyTermOne = priceMatrix[currency][0];
              if(currencyTermOne['term_id_from'] == 1 && currencyTermOne['term_id_to'] == 1){
                plan.prettyPrice = i18n.t('store/price_display single_price_format', {
                  msrp: currencyTermOne.msrp,
                  currency: currency
                });
              }
              else{
                currencyTermOneText = typeof constants[currencyTermOne.term_months] != 'undefined'
                  ? i18n.t('misc/' + constants[currencyTermOne.term_months]) : currencyTermOne.term_months + ' ' +  defaultMultipleTermText;

                plan.prettyPrice = i18n.t('store/price_display single_term_price_format', {
                  msrp: currencyTermOne.msrp,
                  term_text: currencyTermOneText,
                  currency: currency
                });
              }
            }
            else{
              plan.prettyPrice = i18n.t('store/price_display single_term_price_format', {
                msrp: 0,
                term_text: defaultSingularTermText,
                currency: currency
              });
            }

            plan.price = priceMatrix[currency].length > 0 ? priceMatrix[currency][0].msrp : 0;
          }
        });
        state.plans.push(plan);
        state.planIds[plan.planID] = plan;

        var testP3 = state.p3CountryCodes[plan.productID3.slice(0,-3)];
        if(typeof testP3 === 'object' && typeof plan.countries === 'object'){
          state.p3CountryCodes[plan.productID3.slice(0,-3)] = testP3.concat(plan.countries.filter((item)=>testP3.indexOf(item) < 0)).sort();
        }
        else if(typeof plan.countries === 'object'){
          state.p3CountryCodes[plan.productID3.slice(0,-3)] = plan.countries;
        }
      }
    });
    // window.debugConsole('p3CountryCodes: ', state.p3CountryCodes);
  },
  [SET_PCATS](state) {
    let p3s = [];
    state.pcats = [];
    state.plans.map(plan => {
      if (p3s.indexOf(plan.p3) == -1) {
        p3s.push(plan.p3);
      }
    });
    state.pcats = window.PCATS.filter(pcat => p3s.indexOf(pcat.p3) !== -1);
    state.pcats.map(pcat => {
      pcat.title = i18n.t(pcat.title);
      pcat.subTitle = i18n.t(pcat.subTitle);
      pcat.icon = i18n.t(pcat.icon);
      pcat.tileblurb = i18n.t(pcat.tileblurb);
      pcat.desc = i18n.t(pcat.desc);
    });
    state.pcats.sort(function(a, b) {
      return a.p3 < b.p3 ? -1 : a.p3 > b.p3 ? 1 : 0;
    });
    window.debugConsole('all pcats', state.pcats);
  },
  [SET_SUBSCRIBED_CORE_PCATS](state) {
    state.subscribedCoreP3s = [];
    state.userIds.map(userid => {
      if (userid in state.registeredUserIds) {
        var subscribedProdCats = state.registeredUserIds[userid]['prodcats'];
        state.coreP3s.map(coreP3 => {
          if (
            subscribedProdCats.indexOf(coreP3) != -1 &&
            state.subscribedCoreP3s.indexOf(coreP3) == -1
          ) {
            state.subscribedCoreP3s.push(coreP3);
          }
        });
      }
    });
    state.allowedPcatsHome = [];
    if (state.coreP3s) {
      state.coreP3s.map(coreP3 => {
        let corePcat = this.getters['getPcatByP3'](coreP3);
        if (corePcat) {
          if (corePcat.needsDomain || corePcat.isMulti) {
            state.allowedPcatsHome.push(corePcat);
          } else {
            if (state.subscribedCoreP3s.indexOf(coreP3) == -1) {
              state.allowedPcatsHome.push(corePcat);
            }
          }
        }
      });
    }
    // lets keep this piece of code here to get allowedPcats tilesbased on all subscribed products
    // let regDomains = state.registeredDomains.keys();
    // window.debugConsole(regDomains);
    // state.domains.map(domain => {
    //   state.allowedP3sPerDomain[domain] = [];
    //   if (domain in state.registeredDomains) {
    //     var subscribedProdCats = state.registeredDomains[domain]['prodcats'];
    //     var subscribedPLines = state.registeredDomains[domain]['prodlines'];
    //     state.plans.map(plan => {
    //       let pLine = plan.productID3.substr(0, 2);
    //       if (isCompatiblePLine(pLine, subscribedPLines)) {
    //         if (
    //           plan.prerequisites3 ||
    //           subscribedProdCats.includes(plan.prerequisites3)
    //         ) {
    //           if (plan.multiple == 0) {
    //             if (!subscribedProdCats.includes(plan.p3)) {
    //               if (!pcatsToSubscribe.includes(plan.p3))
    //                 pcatsToSubscribe.push(plan.p3);
    //               if (!state.allowedP3sPerDomain[domain].includes(plan.p3))
    //                 state.allowedP3sPerDomain[domain].push(plan.p3);
    //             }
    //           } else {
    //             if (!pcatsToSubscribe.includes(plan.p3))
    //               pcatsToSubscribe.push(plan.p3);
    //             if (!state.allowedP3sPerDomain[domain].includes(plan.p3))
    //               state.allowedP3sPerDomain[domain].push(plan.p3);
    //           }
    //         }
    //       }
    //     });
    //   } else {
    //     state.allowedP3sPerDomain[domain] = state.coreP3s.slice();
    //     state.coreP3s.map(coreP3 => {
    //       if (!pcatsToSubscribe.includes(coreP3)) pcatsToSubscribe.push(coreP3);
    //     });
    //   }
    // });
    // if (pcatsToSubscribe) {
    //   state.allowedPcatsToSubscribe = state.pcats.filter(pcat =>
    //     pcatsToSubscribe.includes(pcat.p3)
    //   );
    // }
    // window.debugConsole(
    //   'allowedPcatsToSubscribe',
    //   state.allowedPcatsToSubscribe,
    //   state.allowedP3sPerDomain
    // );
    state.isLoading = false;
  },
  [SET_DEEP_LINK](state, link) {
    state.productDeepLink = link;
  },
  [SET_NEW_USER_ID](state, userId) {
    state.userIds.push(userId);
  },
  [SET_OFFER_DATA](state, data) {
    state.viewOfferData = data;
  },
  [SET_CURRENCY](state, currency) {
    state.currency = currency;
  },
  [SET_ONBOARDING_STATE](state, loading) {
    state.isOnboarding = loading;
  },
  [SET_GATEWAY_LOADING_STATE](state, loading) {
    state.isGatewayLoading = loading;
  },
  [SET_MIGRATION_OFFER_STATE](state, render) {
    state.isMigrationOffer = render;
  },
  [SET_MIGRATION_STAGE_STATE](state, stage) {
    state.migrationStage = stage;
  },
  [SET_SIDE_BAR_STATE](state, render) {
    state.showSideBar = render;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
    window.debugConsole('state cleared', state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
