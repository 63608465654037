import ApiService from '@/common/api.service';

import {
  SET_ONBOARDING_TOOLTIP,
  COMPLETE_SSI_NOTIFICATION
} from './actions.type';
import {
  UPDATE_ONBOARDING_TOOLTIP
} from './mutations.type';

const genericActions = {
  [SET_ONBOARDING_TOOLTIP](context, tooltip) {
    context.commit(UPDATE_ONBOARDING_TOOLTIP, tooltip);
  },
  [COMPLETE_SSI_NOTIFICATION](context, ssi_notification) {
    return new Promise((resolve) => {
      ApiService.query("/na/site/complete-ssi-notification?msgid=" + ssi_notification)
        .then(({ data }) => {
          resolve(data);
        });
    });
  }
}

export default genericActions