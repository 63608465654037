import genericGetters   from './getters'
import genericActions   from './actions'
import genericMutations from './mutations'

const genericModule = {
  namespaced: true,
  state() {
    return {
      initial_store_onboarding_tooltips : {
        initial_log_in : {
          data: {
            title: 'store/onboarding:tooltip:initial_log_in header',
            show: true, 
            content: 'store/onboarding:tooltip:initial_log_in content',
            next: 'subList',
          },
          options: {
            onboarding:true,
            canSkip: true,
            orientation:{ 
              tooltip: { center: true }
            },
            arrow: false,
          },
        },
        subList : {
          data: {
            title: 'store/onboarding:tooltip:subList header',
            show: true,
            content: 'store/onboarding:tooltip:subList content',
            next: 'subList_log_in',
          },
          options: {
            onboarding:true,
            canSkip: false,
            orientation:{
              tooltip: { x_left:true }
            },
            arrow: true,
            style: {
              left: '90px',
            }
          },
        },
        subList_log_in : {
          data: {
            title: 'store/onboarding:tooltip:subList_log_in header',
            show: true,
            content: 'store/onboarding:tooltip:subList_log_in content',
            next: 'subList_actions',
          },
          options: {
            onboarding:true,
            canSkip: false,
            orientation:{
              tooltip: { x_left:true }
            },
            arrow: true,
            style: {
              left: '27%',
              top: '-138px'
            }
          },
        },
        subList_actions : {
          data: {
            title: 'store/onboarding:tooltip:subList_actions header',
            show: true,
            content: 'store/onboarding:tooltip:subList_actions content',
            next: 'homeOffers',
          },
          options: {
            onboarding:true,
            canSkip: false,
            orientation:{
              tooltip: { x_left:true }
            },
            arrow: true,
            style: {
              left: '76%',
              top: '-138px'
            }
          },
        },
        homeOffers : {
          data: {
            title: 'store/onboarding:tooltip:homeOffers header',
            show: true,
            content: 'store/onboarding:tooltip:homeOffers content',
            next: 'homeOffers_detail',
          },
          options: {
            onboarding:true,
            canSkip: false,
            orientation:{
              tooltip: { x_left:true }
            },
            style: {
              left: '90px',
            },
            arrow: true,
          },
        },
        homeOffers_detail : {
          data: {
            title: 'store/onboarding:tooltip:homeOffers_detail header',
            show: true,
            content: 'store/onboarding:tooltip:homeOffers_detail content',
            next: 'nav',
          },
          options: {
            onboarding:true,
            canSkip: false,
            orientation:{
              tooltip: { x_left:true }
            },
            arrow: true,
            style: {
              left: '80%',
              top: '-161px'
            }
          },
        },
        nav : {
          data: {
            title: 'store/onboarding:tooltip:nav header',
            show: true,
            content: 'store/onboarding:tooltip:nav content',
            left:true
          },
          options: {
            onboarding:true,
            canSkip: false,
            orientation:{
              arrow: { left:true },
              tooltip: { x_left:true }
            },
            style: {
              top: '25px'
            },
            arrow: true,
          },
        },
      },
      active_onboarding_tooltip : {
        id: null,
        data: {},
        options: {}
      },
    }
  },
  getters:   genericGetters,
  actions:   genericActions,
  mutations: genericMutations
}

export default genericModule