<template>
<div>
  <div id="sidenav" class="sidenav" v-if="isSideBarVisiable">
    <link rel="stylesheet" type="text/css" :href="defaultCss()" />
    <link rel="stylesheet" type="text/css" :href="partnerCss()" />
    <div v-if="currentUser.authenticated" class="col-12 text-center">

      <!-- <router-link
        style="overflow-x:visible;"
        exact :to="{
          name: 'dashboard',
        }"
        class="mb-2 row"
        v-b-tooltip.hover="'Dashboard'"
      >
        <i class="fa fa-home" aria-hidden="true"></i>
      </router-link> -->
      <router-link
        style="overflow-x:visible;"
        exact :to="{
          name: 'dashboard',
        }"
        class="mb-3 row"
        v-b-tooltip.hover.right.viewport="$t('store/view:nav home')"
      >
        <i class="fa fa-home" aria-hidden="true"></i>
      </router-link>
      <a @click="ssiSso()" class="mb-2 row" style="cursor:pointer;" v-b-tooltip.hover.right.viewport="$t('store/nav account text')">
        <i class="fa fa-user"></i>
      </a>
      <!-- <router-link
        exact :to="{
          name: 'manage-persons',
        }"
        class="mb-3 row"
        v-b-tooltip.hover.bottom="$t('store/view:nav persons')"
      >
        <i class="fa fa-users" aria-hidden="true"></i>
      </router-link> -->
      <a target="_blank" :href="$t('store/nav help link')" class="mb-2 row" v-b-tooltip.hover.right.viewport="$t('store/nav help text')">
        <i class="fas fa-question"></i>
      </a>
      <a class="mb-3 mt-5 row" @click="logout" style="font-size:1rem" role="button" v-b-tooltip.hover.right.viewport="$t('store/nav logout')">
        <i class="fas fa-sign-out-alt fa-2x"></i>
      </a>
      <FeedbackModal v-b-tooltip.hover.right.viewport="$t('store/nav feedback')" />
    </div>
  </div>
  <div v-else id="header" class="mb-3">
    <link rel="stylesheet" type="text/css" :href="defaultCss()" />
    <link rel="stylesheet" type="text/css" :href="partnerCss()" />
  </div>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { LOGOUT, RESET_APP_STATE } from '@/store/actions.type';
import { API_URL, STYLES_URL } from '@/common/config'; // IMAGES_URL
import FeedbackModal from '@/components/FeedbackModal.vue';
import {
    SET_STORE_LOADING,
} from '../store/actions.type';
// import InviteUserModal from '@/components/InviteUserModal';
export default {
  name: 'SbsHeader',
  components: { FeedbackModal },
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated', 'isSideBarVisiable']),
    ...mapState({
      authenticated: state => state.auth.user.authenticated,
      JwtInterval: state => state.auth.JwtInterval,
    }),
  },
  methods: {
    defaultCss: function() {
      return STYLES_URL + 'site.css';
    },
    partnerCss: function() {
      if (this.isAuthenticated) {
        return STYLES_URL + this.currentUser.partner_id + '/site.css';
      } else return '';
    },
    feedback(event) {
      event.preventDefault();
    },
    ssiSso(){
      this.$store.dispatch(SET_STORE_LOADING, true);
      window.location = "/na/auth/ssi-sso?endpoint=account-management";
    },
    logout(event) {
      event.preventDefault();
      if(this.JwtInterval){
        clearInterval(this.JwtInterval);
      }

      Promise.all([
        this.$store.dispatch(RESET_APP_STATE),
        this.$store.dispatch(LOGOUT)
      ]).then(() => {
        location.replace(API_URL + (this.currentUser.partner_id ? this.currentUser.partner_id : "na") +'/site/logout');
      });
    }
  }
};
</script>

<style scoped>
.dropdown-toggle{
  margin-right: 8vw;
}
.navbar-brand{
  font-size: 1.8rem;
}
.nav-bar{
  padding: 0px;
}
.logo{
  height: 100px;
  width: 100px;
  margin-top:3px;
  background-position: 0px;
  background-size: 80px;
}
.head-title{
  top: 0px;
  position: relative;
}
.sidenav {
  height: 100%;
  width: 6vw;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 40px;
  transition: 0.5s;
  display:block;
}
.sidenav a {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  font-size: 25px;
  display: block;
  transition: 0.3s;
  color: #818181;
}
.sidenav a:hover {
  color: #f1f1f1;
}
.router-link-active{
  color: #ffffff !important;
  box-shadow: 0px 0px 0px 0px #fff!important;
}
</style>