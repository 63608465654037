export const SUBS_LIVE_STATUS = [1, 2, 3];
export const SUB_STATUS_ACTIVE = 1;
export const SUBS_STATUS_NONRENEW = 2;
export const SUBS_STATUS_SUSPENDED = 3;
export const CAMPAIGN_STATUS_ACTIVE = 'active';
export const CAMPAIGN_STATUS_CANCELLED = 'cancelled';
export const CAMPAIGN_STATUS_ERRORED = 'errored';
export const CAMPAIGN_STATUS_SUSPENDED = 'suspended';
export const CAMPAIGN_STATUS_UNPAID = 'unpaid';
export default {
  SUB_STATUS_ACTIVE,
  SUBS_STATUS_SUSPENDED,
  SUBS_STATUS_NONRENEW
};
